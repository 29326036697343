import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { ProductTile } from '../ProductTile';

export interface SectionLandingProductsSliderProps {
  fullWidth?: boolean;
  products?: Array<b2x.ProductApiDto>;
}

export const SectionLandingProductsSlider = ({ fullWidth, products }: SectionLandingProductsSliderProps) => {
  return (
    <b2x.EqualHeight>
      <b2x.SwiperContext>
        {({ navigationNextElRef, navigationPrevElRef }) => (
          <b2x.Div alignItems="center" display="flex">
            <b2x.Div display={{ lg: 'block', xs: 'none' }}>
              <Button innerRef={navigationPrevElRef} variant="blank">
                <Icon name="large-arrow-left" size={30} />
              </Button>
            </b2x.Div>
            <b2x.Listing name="" products={products}>
              <b2x.Swiper
                breakpoints={{
                  lg: {
                    slidesPerView: 2,
                  },
                  md: {
                    slidesPerView: 2.2,
                  },
                  sm: {
                    slidesPerView: 1.1,
                  },
                }}
                navigation={{ custom: true }}
                slides={products?.map((product) => (
                  <ProductTile key={product.id} product={product} />
                ))}
                slidesPerView={1.2}
                spaceBetween={20}
                style={{ flex: 1 }}
              />
            </b2x.Listing>
            <b2x.Div display={{ lg: 'block', xs: 'none' }}>
              <Button innerRef={navigationNextElRef} variant="blank">
                <Icon name="large-arrow-right" size={30} />
              </Button>
            </b2x.Div>
          </b2x.Div>
        )}
      </b2x.SwiperContext>
    </b2x.EqualHeight>
  );
};
