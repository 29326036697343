import './ProductTileStickers.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ProductApiDto } from '../../storefront-api-js-client/src';
import { Icon, isIconName } from './Icon';

export interface ProductTileStickersProps {
  fixedTags?: Array<string>;
  product: ProductApiDto;
  selectedVariant?: ProductApiDto;
  size?: 'large' | 'default';
}

export const ProductTileStickers = ({
  fixedTags,
  product,
  selectedVariant,
  size = 'default',
}: ProductTileStickersProps) => {
  const { stickersProductWithoutImage } = b2x.useStickersProduct({
    fixedTags: fixedTags,
    product: selectedVariant ?? product,
  });

  return (
    <div className="product-tile-stickers position-absolute w-100 bottom-0 end-0 p-2" style={{ pointerEvents: 'none' }}>
      <b2x.Div display="flex" gap={2} justifyContent="end">
        {stickersProductWithoutImage?.map(({ backgroundColor, code, contentSectionId, iconName, label, textColor }) => (
          <b2x.Badge
            backgroundColor={backgroundColor}
            backgroundVariant={!backgroundColor ? 'white' : undefined}
            className={classnames(code)}
            key={contentSectionId}
            textColor={textColor}
            textVariant={!textColor ? 'secondary' : undefined}
          >
            {isIconName(iconName) && (
              <>
                <Icon name={iconName} />{' '}
              </>
            )}
            {label}
          </b2x.Badge>
          // <b2x.Div
          //   className={classnames(
          //     sticker.code,
          //     'px-2 fw-bold rounded-1',
          //     { 'extra-small': size === 'default' },
          //     { 'bg-white': !sticker.backgroundColor },
          //     { 'border border-white': !sticker.borderColor },
          //     { 'text-secondary': !sticker.textColor }
          //   )}
          //   key={sticker.code}
          //   paddingX={1}
          //   style={{
          //     backgroundColor: sticker.backgroundColor,
          //     border: sticker.borderColor ? `1px solid ${sticker.borderColor}` : undefined,
          //     color: sticker.textColor,
          //   }}
          // >
          //   {isIconName(sticker.iconName) && (
          //     <>
          //       <Icon name={sticker.iconName} />{' '}
          //     </>
          //   )}
          //   {sticker.label}
          // </b2x.Div>
        ))}
      </b2x.Div>
    </div>
  );
};
