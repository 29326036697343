import classNames from 'classnames';

export interface SectionProps {
  children?: React.ReactNode;
  className?: string;
}
export const Section = ({ children, className }: SectionProps) => {
  return (
    <section className={classNames('my-3 my-xl-5', className)}>
      <div className={classNames('container-xxl')}>{children && <div className="section-content">{children}</div>}</div>
    </section>
  );
};
