import { Resource } from '../i18n';

export const de: Resource = {
  assembledProduct: {
    discountUpTo: '-{{percentage}}%', // da revisionare
    discountUpToMini: '-{{percentage}}%', // da revisionare
  },
  form: {
    addToCartForm: {
      buttons: {
        submit: { label: 'In den Warenkorb legen' },
      },
      misc: {
        productVariants: 'Format',
      },
    },
  },
  header: {
    sendTo: 'Land {{country}} | {{locale}}',
  },
  misc: {
    addToCart: 'Hinzufügen',
    code: 'Code',
    customise: 'Anpassen',
    storeName: 'De Cecco',
    youSearchedFor: 'Sie haben gesucht:',
  },
  product: {
    freeShippingFrom: 'Kostenloser Versand ab {{freeShippingFromAmount}}',
    relatedProduct: {
      title: 'Das könnte Ihnen auch gefallen',
    },
    size: 'Format',
  },
  welcome: 'Willkommen im De Cecco Store',
};
