export const Icomoon = () => (
  <svg
    aria-hidden="true"
    style={{ height: 0, overflow: 'hidden', position: 'absolute', width: 0 }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <symbol id="icon-shipping" viewBox="0 0 39 32">
        <path d="M24.447 26.594c0-0.004-0-0.009-0-0.013 0-1.008 0.278-1.952 0.761-2.758l-0.014 0.024h-11.877c0.473 0.787 0.752 1.737 0.752 2.752 0 2.987-2.422 5.409-5.409 5.409s-5.409-2.422-5.409-5.409c0-1.015 0.28-1.965 0.766-2.776l-0.014 0.025h-2.68c0 0-0 0-0 0-0.732 0-1.325-0.593-1.325-1.325 0-0.003 0-0.006 0-0.008v0-21.185c-0-0.003-0-0.006-0-0.009 0-0.731 0.593-1.324 1.324-1.324 0 0 0.001 0 0.001 0h24.448c0 0 0 0 0.001 0 0.733 0 1.327 0.594 1.327 1.327 0 0.002 0 0.004 0 0.006v-0 6.82h5.193c0.002 0 0.005-0 0.007-0 0.363 0 0.692 0.147 0.93 0.385l4.891 4.891c0.243 0.241 0.393 0.574 0.393 0.943 0 0.001 0 0.002 0 0.002v-0 8.143c0 0.736-0.597 1.333-1.333 1.333v0h-2.677c0.471 0.781 0.75 1.724 0.75 2.732 0 0.005 0 0.011-0 0.016v-0.001c0 2.984-2.419 5.402-5.402 5.402s-5.402-2.419-5.402-5.402v0zM27.101 26.594c0.002 1.515 1.23 2.742 2.745 2.742s2.745-1.229 2.745-2.745c0-1.515-1.227-2.743-2.742-2.745h-0c-1.517 0.001-2.746 1.23-2.748 2.747v0zM5.916 26.594c0.002 1.515 1.23 2.742 2.745 2.742s2.745-1.229 2.745-2.745c0-1.515-1.227-2.743-2.742-2.745h-0c-1.517 0-2.747 1.23-2.747 2.747 0 0 0 0 0 0.001v-0zM27.101 21.185h8.753v-6.267l-4.112-4.112h-4.641zM2.653 21.185h21.794v-18.526h-21.794z"></path>
      </symbol>
      <symbol id="icon-discount" viewBox="0 0 32 32">
        <path d="M16.115 31.997c-0.389-0.002-0.741-0.156-1-0.406l0 0-14.677-14.193c-0.271-0.26-0.44-0.625-0.44-1.029 0-0.003 0-0.005 0-0.008v0-14.927c0-0 0-0 0-0 0-0.407 0.169-0.774 0.44-1.036l0-0c0.256-0.248 0.605-0.4 0.99-0.4 0.006 0 0.012 0 0.018 0h0.040l14.679 0.467c0.365 0.011 0.693 0.158 0.938 0.391l14.449 13.64c0.282 0.261 0.458 0.634 0.458 1.047 0 0.389-0.156 0.742-0.409 1l0-0-14.448 15.005c-0.254 0.269-0.611 0.439-1.008 0.448l-0.002 0zM2.884 15.753l13.2 12.763 12.433-12.922-12.991-12.263-12.642-0.409zM10.298 19.865c-0.256-0.259-0.413-0.616-0.413-1.009 0-0.403 0.166-0.767 0.432-1.028l0-0 7.164-7.023c0.26-0.257 0.618-0.416 1.012-0.416 0.795 0 1.439 0.644 1.439 1.439 0 0.4-0.163 0.762-0.427 1.023l-0 0-7.173 7.031c-0.258 0.259-0.615 0.42-1.009 0.42-0.403 0-0.767-0.168-1.026-0.437l-0-0zM17.302 18.865c-0-0.007-0-0.015-0-0.023 0-0.659 0.534-1.193 1.193-1.193s1.193 0.534 1.193 1.193c0 0.659-0.534 1.193-1.193 1.193-0.002 0-0.004 0-0.005 0h0c-0.001 0-0.003 0-0.004 0-0.647 0-1.172-0.519-1.183-1.163v-0.001zM10.127 11.818c-0-0.006-0-0.013-0-0.020 0-0.663 0.537-1.2 1.2-1.2s1.2 0.537 1.2 1.2c0 0.663-0.537 1.2-1.2 1.2-0.001 0-0.002 0-0.003 0h0c-0.003 0-0.006 0-0.009 0-0.652 0-1.181-0.525-1.188-1.175v-0.001z"></path>
      </symbol>
      <symbol id="icon-order-by" viewBox="0 0 28 32">
        <path d="M14.727 10.952l-6.289 6.289c-0.227 0.229-0.541 0.371-0.889 0.371s-0.662-0.142-0.889-0.371l-0-0-6.297-6.289c-0.228-0.228-0.368-0.542-0.368-0.889 0-0.694 0.563-1.257 1.257-1.257 0.347 0 0.662 0.141 0.889 0.368v0l4.142 4.142v-12.057c0-0.695 0.563-1.258 1.258-1.258s1.258 0.563 1.258 1.258v0 12.059l4.142-4.142c0.228-0.228 0.542-0.368 0.889-0.368 0.694 0 1.257 0.563 1.257 1.257 0 0.347-0.141 0.662-0.368 0.889v0z"></path>
        <path d="M13.746 21.048l6.291-6.291c0.227-0.229 0.541-0.371 0.889-0.371s0.662 0.142 0.889 0.371l6.289 6.291c0.228 0.228 0.369 0.542 0.369 0.89 0 0.695-0.563 1.258-1.258 1.258-0.347 0-0.661-0.141-0.889-0.368v0l-4.142-4.144v12.065c0 0.695-0.563 1.258-1.258 1.258s-1.258-0.563-1.258-1.258v0-12.065l-4.142 4.144c-0.228 0.228-0.542 0.368-0.889 0.368-0.694 0-1.257-0.563-1.257-1.257 0-0.347 0.141-0.662 0.368-0.889v0z"></path>
      </symbol>
      <symbol id="icon-profile" viewBox="0 0 30 32">
        <path d="M28.588 32c-0.752 0-1.362-0.61-1.362-1.362v0-3.677c0.003-0.073 0.005-0.158 0.005-0.244 0-3.065-2.404-5.569-5.43-5.729l-0.014-0.001h-13.617c-1.512 0.011-2.869 0.668-3.809 1.71l-0.004 0.005c-1.013 1.105-1.634 2.584-1.634 4.209 0 0.020 0 0.039 0 0.059l-0-0.003v3.677c0 0.752-0.61 1.362-1.362 1.362s-1.362-0.61-1.362-1.362v0-3.677c-0-0.018-0-0.040-0-0.062 0-2.337 0.896-4.466 2.363-6.060l-0.006 0.006c1.441-1.587 3.51-2.581 5.812-2.587h13.618c4.547 0.157 8.174 3.882 8.174 8.453 0 0.088-0.001 0.175-0.004 0.263l0-0.013v3.677c-0.003 0.75-0.612 1.356-1.362 1.356-0.003 0-0.006 0-0.009-0h0z"></path>
        <path d="M14.975 0c4.806 0 8.703 3.896 8.703 8.703s-3.896 8.703-8.703 8.703c-4.806 0-8.703-3.896-8.703-8.703v0c0.007-4.804 3.899-8.696 8.702-8.703h0.001zM14.975 14.683c3.302 0 5.979-2.677 5.979-5.979s-2.677-5.979-5.979-5.979c-3.302 0-5.978 2.676-5.979 5.978v0c0.006 3.3 2.679 5.974 5.979 5.981h0.001z"></path>
      </symbol>
      <symbol id="icon-customer" viewBox="0 0 32 32">
        <path d="M30.897 27.034c-0.609 0-1.103-0.494-1.103-1.103v0-9.931c0-7.618-6.175-13.793-13.793-13.793s-13.793 6.175-13.793 13.793v0 9.931c0 0.609-0.494 1.103-1.103 1.103s-1.103-0.494-1.103-1.103v0-9.931c0-8.836 7.163-16 16-16 4.418 0 8.418 1.791 11.314 4.686v0c2.895 2.88 4.686 6.866 4.686 11.27 0 0.015-0 0.030-0 0.046v-0.002 9.931c0 0.609-0.494 1.103-1.103 1.103v0z"></path>
        <path d="M27.586 32h-1.655c-2.438 0-4.414-1.976-4.414-4.414v0-4.966c0-2.438 1.976-4.414 4.414-4.414v0h4.966c0.609 0 1.103 0.494 1.103 1.103v0 8.276c0 2.438-1.976 4.414-4.414 4.414v0zM25.931 20.414c-1.219 0-2.207 0.988-2.207 2.207v0 4.966c0 1.219 0.988 2.207 2.207 2.207v0h1.655c1.219 0 2.207-0.988 2.207-2.207v0-7.172zM6.069 32h-1.655c-2.438 0-4.414-1.976-4.414-4.414v0-8.276c0-0.609 0.494-1.103 1.103-1.103v0h4.966c2.438 0 4.414 1.976 4.414 4.414v0 4.966c0 2.438-1.976 4.414-4.414 4.414v0zM2.207 20.414v7.172c0 1.219 0.988 2.207 2.207 2.207v0h1.655c1.219 0 2.207-0.988 2.207-2.207v0-4.966c0-1.219-0.988-2.207-2.207-2.207v0z"></path>
      </symbol>
      <symbol id="icon-logout" viewBox="0 0 32 32">
        <path d="M24.888 8.889l-2.505 2.507 2.808 2.826h-14.525v3.561h14.523l-2.806 2.803 2.507 2.525 7.11-7.112zM3.561 3.561h12.438v-3.561h-12.438c-1.967 0-3.561 1.594-3.561 3.561v0 24.883c0 1.967 1.594 3.561 3.561 3.561v0h12.438v-3.561h-12.438z"></path>
      </symbol>
      <symbol id="icon-privacy" viewBox="0 0 29 32">
        <path d="M4 13.5h21c2.209 0 4 1.791 4 4v0 10.5c0 2.209-1.791 4-4 4v0h-21c-2.209 0-4-1.791-4-4v0-10.5c0-2.209 1.791-4 4-4v0zM25 30c1.105 0 2-0.895 2-2v0-10.5c0-1.105-0.895-2-2-2v0h-21c-1.105 0-2 0.895-2 2v0 10.5c0 1.105 0.895 2 2 2v0z"></path>
        <path d="M22 15.5c-0.552 0-1-0.448-1-1v0-6c0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5v0 6c0 0.552-0.448 1-1 1s-1-0.448-1-1v0-6c0-4.694 3.806-8.5 8.5-8.5s8.5 3.806 8.5 8.5v0 6c0 0.552-0.448 1-1 1v0z"></path>
      </symbol>
      <symbol id="icon-wishlist" viewBox="0 0 36 32">
        <path d="M26.073 0.004c5.635 0.003 10.202 4.572 10.202 10.208 0 2.817-1.141 5.367-2.986 7.214l-14.166 14.161c-0.252 0.252-0.6 0.407-0.984 0.407s-0.732-0.156-0.984-0.407l-14.166-14.161c-1.785-1.836-2.886-4.346-2.886-7.113 0-5.638 4.57-10.208 10.208-10.208 2.767 0 5.277 1.101 7.115 2.889l-0.002-0.002 0.715 0.715 0.715-0.715c1.836-1.845 4.378-2.987 7.187-2.987 0.011 0 0.022 0 0.033 0h-0.002zM18.143 28.646l13.183-13.183c1.385-1.35 2.245-3.234 2.245-5.319 0-4.101-3.324-7.425-7.425-7.425-2.085 0-3.969 0.859-5.318 2.243l-0.002 0.002-1.702 1.695c-0.252 0.252-0.6 0.407-0.984 0.407s-0.732-0.156-0.984-0.407l-1.699-1.699c-1.336-1.303-3.165-2.106-5.181-2.106-4.101 0-7.425 3.324-7.425 7.425 0 2.016 0.803 3.844 2.107 5.182l-0.002-0.002z"></path>
      </symbol>
      <symbol id="icon-cart" viewBox="0 0 33 32">
        <path d="M12.551 26.241c1.591 0 2.88 1.289 2.88 2.88s-1.289 2.88-2.88 2.88c-1.591 0-2.88-1.289-2.88-2.88v0c0.002-1.59 1.29-2.878 2.88-2.88h0z"></path>
        <path d="M27.535 26.241c1.591 0 2.88 1.289 2.88 2.88s-1.289 2.88-2.88 2.88c-1.591 0-2.88-1.289-2.88-2.88v0c0.002-1.59 1.29-2.878 2.88-2.88h0z"></path>
        <path d="M27.046 23.071h-13.565c-0.028 0.001-0.061 0.001-0.095 0.001-1.989 0-3.651-1.398-4.059-3.264l-0.005-0.027-2.323-11.286q-0.014-0.057-0.024-0.117l-1.152-5.594h-4.431c-0.768 0-1.391-0.623-1.391-1.391s0.623-1.391 1.391-1.391v0h5.565c0.669 0.001 1.228 0.474 1.361 1.104l0.002 0.009 1.163 5.651h22.518c0.768 0 1.391 0.623 1.391 1.391 0 0.095-0.010 0.188-0.028 0.277l0.002-0.009-2.226 11.347v0.011c-0.413 1.892-2.075 3.288-4.062 3.288-0.011 0-0.022-0-0.033-0h0.002zM13.468 20.288h13.578c0.009 0 0.019 0 0.030 0 0.647 0 1.189-0.448 1.333-1.051l0.002-0.009 1.899-9.682h-20.253l1.992 9.675c0.144 0.616 0.689 1.068 1.339 1.068 0.018 0 0.037-0 0.055-0.001l-0.003 0z"></path>
      </symbol>
      <symbol id="icon-menu" viewBox="0 0 32 32">
        <path d="M29.39 7.335h-26.78c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5v0h26.78c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5v0z"></path>
        <path d="M29.39 16.785h-26.78c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5v0h26.78c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5v0z"></path>
        <path d="M29.39 25.65h-26.78c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5v0h26.78c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5v0z"></path>
      </symbol>
      <symbol id="icon-delete" viewBox="0 0 32 32">
        <path d="M17.040 16l14.745-14.745c0.133-0.133 0.215-0.317 0.215-0.52 0-0.406-0.329-0.735-0.735-0.735-0.203 0-0.387 0.082-0.52 0.215l-14.745 14.745-14.745-14.745c-0.133-0.133-0.317-0.215-0.52-0.215-0.406 0-0.735 0.329-0.735 0.735 0 0.203 0.082 0.387 0.215 0.52l14.745 14.745-14.745 14.745c-0.133 0.133-0.216 0.317-0.216 0.52 0 0.399 0.318 0.724 0.715 0.735l0.001 0c0.196-0.004 0.372-0.085 0.5-0.215l0-0 14.785-14.745 14.745 14.745c0.128 0.13 0.304 0.211 0.499 0.215h0.001c0.398-0.011 0.716-0.336 0.716-0.735 0-0.203-0.082-0.387-0.216-0.52v0z"></path>
      </symbol>
      <symbol id="icon-large-arrow-left" viewBox="0 0 32 32">
        <path d="M7.87 16.445l15.375 15.37c0.113 0.113 0.27 0.183 0.442 0.183 0.346 0 0.626-0.28 0.626-0.626 0-0.173-0.070-0.329-0.183-0.442v0l-14.93-14.93 14.93-14.93c0.111-0.113 0.179-0.269 0.179-0.44 0-0.346-0.279-0.627-0.624-0.63h-0c-0.001 0-0.003-0-0.005-0-0.171 0-0.325 0.071-0.435 0.185l-0 0-15.375 15.37c-0.114 0.114-0.184 0.271-0.184 0.445s0.070 0.331 0.184 0.445v0z"></path>
      </symbol>
      <symbol id="icon-large-arrow-right" viewBox="0 0 32 32">
        <path d="M24.13 15.555l-15.375-15.37c-0.113-0.113-0.27-0.183-0.443-0.183-0.346 0-0.626 0.28-0.626 0.626 0 0.173 0.070 0.329 0.183 0.442l14.93 14.93-14.93 14.93c-0.111 0.113-0.179 0.269-0.179 0.44 0 0.346 0.279 0.627 0.624 0.63h0c0.001 0 0.003 0 0.005 0 0.171 0 0.325-0.071 0.435-0.185l0-0 15.375-15.37c0.114-0.114 0.184-0.271 0.184-0.445s-0.070-0.331-0.184-0.445v0z"></path>
      </symbol>
      <symbol id="icon-minus" viewBox="0 0 32 32">
        <path d="M31.285 16.715h-30.57c-0.395 0-0.715-0.32-0.715-0.715s0.32-0.715 0.715-0.715h30.57c0.395 0 0.715 0.32 0.715 0.715s-0.32 0.715-0.715 0.715v0z"></path>
      </symbol>
      <symbol id="icon-plus" viewBox="0 0 32 32">
        <path d="M31.285 15.285h-14.57v-14.57c0-0.395-0.32-0.715-0.715-0.715s-0.715 0.32-0.715 0.715v0 14.57h-14.57c-0.395 0-0.715 0.32-0.715 0.715s0.32 0.715 0.715 0.715h14.57v14.57c0 0.395 0.32 0.715 0.715 0.715s0.715-0.32 0.715-0.715v0-14.57h14.57c0.395 0 0.715-0.32 0.715-0.715s-0.32-0.715-0.715-0.715v0z"></path>
      </symbol>
      <symbol id="icon-small-arrow-down" viewBox="0 0 32 32">
        <path d="M17.4 24.41l14-14c0.254-0.332 0.407-0.752 0.407-1.209 0-1.105-0.895-2-2-2-0.458 0-0.879 0.154-1.216 0.412l0.005-0.003-12.595 12.595-12.615-12.615c-0.369-0.483-0.945-0.791-1.593-0.791-1.105 0-2 0.895-2 2 0 0.647 0.307 1.222 0.784 1.588l0.005 0.004 14 14c0.363 0.365 0.865 0.592 1.42 0.592 0.545 0 1.040-0.218 1.4-0.572l-0 0z"></path>
      </symbol>
      <symbol id="icon-small-arrow-left" viewBox="0 0 32 32">
        <path d="M7.59 17.4l14 14c0.332 0.254 0.752 0.407 1.209 0.407 1.105 0 2-0.895 2-2 0-0.458-0.154-0.879-0.412-1.216l0.003 0.005-12.595-12.595 12.615-12.615c0.483-0.369 0.791-0.945 0.791-1.593 0-1.105-0.895-2-2-2-0.647 0-1.222 0.307-1.588 0.784l-0.003 0.005-14 14c-0.365 0.363-0.592 0.865-0.592 1.42 0 0.545 0.218 1.040 0.572 1.4l-0-0z"></path>
      </symbol>
      <symbol id="icon-small-arrow-right" viewBox="0 0 32 32">
        <path d="M24.41 14.6l-14-14c-0.332-0.254-0.752-0.407-1.209-0.407-1.105 0-2 0.895-2 2 0 0.458 0.154 0.879 0.412 1.216l-0.003-0.005 12.595 12.595-12.615 12.615c-0.483 0.369-0.791 0.945-0.791 1.593 0 1.105 0.895 2 2 2 0.647 0 1.222-0.307 1.588-0.784l0.004-0.005 14-14c0.365-0.363 0.592-0.865 0.592-1.42 0-0.545-0.218-1.040-0.572-1.4l0 0z"></path>
      </symbol>
      <symbol id="icon-small-arrow-up" viewBox="0 0 32 32">
        <path d="M14.6 7.59l-14 14c-0.254 0.332-0.407 0.752-0.407 1.209 0 1.105 0.895 2 2 2 0.458 0 0.879-0.154 1.216-0.412l-0.005 0.003 12.595-12.595 12.615 12.615c0.369 0.483 0.945 0.791 1.593 0.791 1.105 0 2-0.895 2-2 0-0.647-0.307-1.222-0.784-1.588l-0.005-0.003-14-14c-0.363-0.365-0.865-0.592-1.42-0.592-0.545 0-1.040 0.218-1.4 0.572l0-0z"></path>
      </symbol>
      <symbol id="icon-info" viewBox="0 0 32 32">
        <path d="M15.5 12.25h1.3v13.35h-1.3v-13.35z"></path>
        <path d="M16.15 6.9c-0.6 0-1.050 0.5-1.050 1.050s0.45 1 1.050 1 1.050-0.45 1.050-1.050c0-0.5-0.45-1-1.050-1z"></path>
        <path d="M16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16c8.8 0 16-7.2 16-16s-7.2-16-16-16zM16 29.85c-7.65 0-13.85-6.2-13.85-13.85s6.2-13.85 13.85-13.85c7.65 0 13.85 6.2 13.85 13.85s-6.2 13.85-13.85 13.85z"></path>
      </symbol>
      <symbol id="icon-discount" viewBox="0 0 32 32">
        <path d="M17.938 9.438c-1.551 0-2.813 1.262-2.813 2.813s1.262 2.813 2.813 2.813 2.813-1.262 2.813-2.813-1.262-2.813-2.813-2.813zM17.938 13.188c-0.517 0-0.938-0.421-0.938-0.938s0.421-0.938 0.938-0.938 0.938 0.421 0.938 0.938-0.421 0.938-0.938 0.938z"></path>
        <path d="M25.438 16.938c-1.551 0-2.813 1.262-2.813 2.813s1.262 2.813 2.813 2.813 2.813-1.262 2.813-2.813-1.262-2.813-2.813-2.813zM25.438 20.688c-0.517 0-0.938-0.421-0.938-0.938s0.421-0.938 0.938-0.938 0.938 0.421 0.938 0.938-0.421 0.938-0.938 0.938z"></path>
        <path d="M25.957 9.595c-0.431-0.287-1.013-0.171-1.3 0.26l-7.5 11.25c-0.287 0.431-0.171 1.013 0.26 1.3s1.013 0.171 1.3-0.26l7.5-11.25c0.287-0.431 0.171-1.013-0.26-1.3z"></path>
        <path d="M31.063 5.688h-30.125c-0.518 0-0.938 0.42-0.938 0.938v5.625c0 0.518 0.42 0.938 0.938 0.938 1.551 0 2.813 1.262 2.813 2.813s-1.262 2.813-2.813 2.813c-0.518 0-0.938 0.42-0.938 0.938v5.625c0 0.518 0.42 0.938 0.938 0.938h30.125c0.518 0 0.938-0.42 0.938-0.938v-18.75c0-0.518-0.42-0.938-0.938-0.938zM30.125 24.438h-20.75v-0.938c0-0.518-0.42-0.938-0.938-0.938s-0.938 0.42-0.938 0.938v0.938h-5.625v-3.844c2.137-0.436 3.75-2.33 3.75-4.593s-1.613-4.158-3.75-4.593v-3.844h5.703v0.938c0 0.518 0.42 0.938 0.938 0.938s0.938-0.42 0.938-0.938v-0.938h20.672v16.875z"></path>
        <path d="M8.438 16.938c-0.518 0-0.938 0.42-0.938 0.938v1.875c0 0.518 0.42 0.938 0.938 0.938s0.938-0.42 0.938-0.938v-1.875c0-0.518-0.42-0.938-0.938-0.938z"></path>
        <path d="M8.438 11.313c-0.518 0-0.938 0.42-0.938 0.938v1.875c0 0.518 0.42 0.938 0.938 0.938s0.938-0.42 0.938-0.938v-1.875c0-0.518-0.42-0.938-0.938-0.938z"></path>
      </symbol>
      <symbol id="icon-instagram" viewBox="0 0 32 32">
        <path d="M22.145 11.355c-0.27-0.685-0.815-1.23-1.5-1.5-1.035-0.41-3.5-0.315-4.645-0.315s-3.605-0.095-4.645 0.315c-0.685 0.27-1.23 0.815-1.5 1.5-0.41 1.035-0.315 3.5-0.315 4.645s-0.095 3.605 0.315 4.645c0.27 0.685 0.815 1.23 1.5 1.5 1.035 0.41 3.5 0.315 4.645 0.315s3.605 0.095 4.645-0.315c0.685-0.27 1.23-0.815 1.5-1.5 0.41-1.035 0.315-3.5 0.315-4.645s0.095-3.605-0.315-4.645zM16 20.040c-2.23 0-4.040-1.81-4.040-4.040s1.81-4.040 4.040-4.040h0.015c2.225 0 4.030 1.81 4.025 4.040 0 2.23-1.81 4.040-4.040 4.040zM21.15 11.795c0 0.52-0.42 0.94-0.94 0.94s-0.94-0.42-0.94-0.94 0.42-0.94 0.94-0.94c0.52 0 0.94 0.42 0.94 0.94 0 0 0 0 0 0z"></path>
        <path d="M16 13.37c-1.45 0-2.625 1.175-2.625 2.625s1.175 2.625 2.625 2.625c1.45 0 2.625-1.175 2.625-2.625s-1.175-2.625-2.625-2.625z"></path>
        <path d="M16.090 0.050c-8.835 0-16 7.165-16 16s7.165 16 16 16 16-7.165 16-16-7.165-16-16-16zM23.82 19.25c0.020 1.225-0.435 2.41-1.27 3.3-0.89 0.84-2.075 1.3-3.3 1.27-1.3 0.075-5.2 0.075-6.5 0-1.225 0.020-2.41-0.435-3.3-1.27-0.84-0.89-1.3-2.075-1.27-3.3-0.075-1.3-0.075-5.2 0-6.5-0.025-1.225 0.435-2.41 1.27-3.3 0.89-0.84 2.075-1.295 3.3-1.27 1.3-0.075 5.2-0.075 6.5 0 1.225-0.020 2.41 0.435 3.3 1.275 0.84 0.89 1.3 2.075 1.27 3.3v0c0.075 1.3 0.075 5.195 0 6.495z"></path>
      </symbol>
      <symbol id="icon-delivery" viewBox="0 0 32 32">
        <path d="M31.6 13.7l-4-4c-0.25-0.25-0.6-0.4-0.95-0.4h-4v-5.3c0-0.75-0.6-1.35-1.35-1.35h-20c-0.7 0-1.3 0.6-1.3 1.35v17.35c0 0.75 0.6 1.35 1.35 1.35h1.8c-0.3 0.6-0.45 1.3-0.45 2 0 2.55 2.1 4.65 4.65 4.65s4.65-2.1 4.65-4.65c0-0.7-0.2-1.4-0.45-2h8.95c-0.3 0.6-0.45 1.3-0.45 2 0 2.55 2.1 4.65 4.65 4.65s4.65-2.1 4.65-4.65c0-0.7-0.2-1.4-0.45-2h1.8c0.75 0 1.35-0.6 1.35-1.35v-6.7c-0.050-0.35-0.2-0.7-0.45-0.95zM2.65 5.35h17.35v14.65h-17.35v-14.65zM9.35 24.65c0 1.1-0.9 2-2 2s-2-0.9-2-2 0.9-2 2-2 2 0.9 2 2zM26.65 24.65c0 1.1-0.9 2-2 2s-2-0.9-2-2 0.9-2 2-2c1.1 0 2 0.9 2 2zM29.35 20h-6.7v-8h3.45l3.2 3.2v4.8z"></path>
      </symbol>
      <symbol id="icon-email" viewBox="0 0 32 32">
        <path d="M31.95 7c-0.15-2.25-2-4.1-4.35-4.1h-23.25c0 0 0 0 0 0-2.3 0-4.15 1.8-4.35 4.050 0 0.1 0 0.2 0 0.3 0 0 0 0 0 0v17.45c0 2.4 1.95 4.35 4.35 4.35h23.3c2.4 0 4.35-1.95 4.35-4.35v-17.45c0 0 0 0 0 0 0-0.050 0-0.15-0.050-0.25zM4.35 5.8h23.25c0.55 0 1.050 0.35 1.3 0.8l-12.9 9.1-12.95-9.050c0.25-0.5 0.75-0.85 1.3-0.85zM27.65 26.2h-23.3c-0.8 0-1.45-0.65-1.45-1.45v-14.7l12.25 8.6c0.25 0.2 0.55 0.25 0.85 0.25s0.6-0.1 0.85-0.25l12.25-8.6v14.65c0 0.8-0.65 1.5-1.45 1.5z"></path>
      </symbol>
      <symbol id="icon-youtube" viewBox="0 0 32 32">
        <path d="M16 0c-8.835 0-16 7.165-16 16s7.165 16 16 16 16-7.165 16-16-7.165-16-16-16zM23.18 19.62c-0.175 0.645-0.675 1.145-1.32 1.32-1.945 0.23-3.905 0.33-5.865 0.31-1.96 0.025-3.92-0.085-5.865-0.32-0.645-0.175-1.145-0.675-1.32-1.32-0.215-1.19-0.315-2.395-0.31-3.605-0.005-1.21 0.095-2.415 0.31-3.605 0.18-0.645 0.68-1.15 1.32-1.335 1.945-0.23 3.905-0.33 5.865-0.31 1.96-0.025 3.92 0.085 5.865 0.32 0.645 0.175 1.15 0.675 1.325 1.32v0c0.22 1.195 0.325 2.405 0.31 3.62 0.005 1.21-0.095 2.415-0.31 3.605z"></path>
        <path d="M14.135 18.645l4.6-2.64-4.6-2.645v5.285z"></path>
      </symbol>
      <symbol id="icon-facebook" viewBox="0 0 32 32">
        <path d="M16 0c-8.835 0-16 7.165-16 16s7.165 16 16 16 16-7.165 16-16-7.165-16-16-16zM18.81 11.77h-0.155c-0.38 0-0.75-0.015-1.12 0-0.51 0.020-0.89 0.215-0.915 0.855-0.020 0.465-0.020 1.54-0.020 1.54h2.135c-0.090 0.745-0.185 1.455-0.28 2.18h-1.84v5.45h-2.25v-5.46h-1.85v-2.17h1.855v-0.17c0-0.44-0.010-0.87 0-1.31 0-0.28 0.025-0.56 0.070-0.835 0.090-0.65 0.445-1.235 0.975-1.62 0.475-0.33 1.045-0.505 1.62-0.5 0.385-0.010 0.77 0.010 1.15 0.025 0.21 0.010 0.42 0.040 0.62 0.060v1.95z"></path>
      </symbol>
      <symbol id="icon-hidden" viewBox="0 0 32 32">
        <path d="M3.85 1.643l-2.053 2.052 26.422 26.423 2.053-2.053-26.422-26.422z"></path>
        <path d="M6.134 8.034l1.933 1.933c-2.116 1.801-3.8 4.043-4.889 6.005l-0.003 0.007c3.032 5.069 7.843 9.86 13.914 9.133 1.639-0.196 3.168-0.756 4.565-1.558l1.956 1.956c-2.723 1.748-5.844 2.673-9.226 2.216-6.098-0.825-11.084-5.826-14.227-11.7 1.476-2.97 3.495-5.81 5.978-7.991zM10.53 5.169c1.705-0.76 3.55-1.207 5.521-1.239 0.109-0.001 1.408 0.061 1.997 0.158 0.369 0.061 0.737 0.137 1.1 0.232 5.784 1.507 10.070 6.397 12.764 11.435-1.13 2.283-2.611 4.51-4.376 6.419l-1.874-1.874c1.304-1.406 2.392-2.97 3.229-4.488 0 0-0.842-1.354-1.48-2.204-0.411-0.546-0.842-1.077-1.296-1.588-0.358-0.403-1.748-1.772-2.079-2.057-2.22-1.909-4.826-3.406-7.952-3.381-1.21 0.019-2.375 0.258-3.48 0.66l-2.073-2.073z"></path>
        <path d="M11.395 13.295l1.591 1.59c-0.104 0.316-0.161 0.655-0.161 1.005 0 1.771 1.438 3.209 3.209 3.209 0.351 0 0.689-0.056 1.005-0.161l1.591 1.591c-0.768 0.431-1.653 0.676-2.595 0.676-2.934 0-5.315-2.381-5.315-5.315 0-0.942 0.246-1.827 0.676-2.595zM15.936 10.576c0.032-0.001 0.066-0.001 0.098-0.001 2.933 0 5.315 2.382 5.315 5.315 0 0.033 0 0.066-0 0.098l-5.412-5.412z"></path>
      </symbol>
      <symbol id="icon-visible" viewBox="0 0 32 32">
        <path d="M16.342 3.955c7.047 0.1 12.828 6.436 15.724 11.887 0 0-1.019 2.085-1.975 3.42-0.462 0.646-0.949 1.275-1.46 1.883-0.365 0.433-0.741 0.855-1.132 1.265-3.49 3.665-8.502 6.4-13.795 5.338-5.88-1.18-10.676-6.218-13.559-11.637 0 0 1.024-2.087 1.985-3.42 0.431-0.598 0.882-1.18 1.356-1.744 0.362-0.432 0.739-0.854 1.127-1.264 3.084-3.249 7.040-5.751 11.73-5.729zM16.317 6.621c-3.925-0.015-7.189 2.177-9.772 4.898-0.351 0.37-0.69 0.751-1.018 1.142-0.431 0.515-0.843 1.046-1.236 1.59s-0.795 1.23-1.114 1.813c2.542 4.271 6.357 8.129 11.051 9.071 4.374 0.878 8.456-1.533 11.34-4.562 0.353-0.37 0.693-0.752 1.022-1.143 0.467-0.555 0.91-1.129 1.332-1.719 0.39-0.546 0.793-1.234 1.111-1.817-2.638-4.415-7.151-9.186-12.717-9.273z"></path>
        <path d="M16.105 10.634c-2.948 0-5.343 2.395-5.343 5.343s2.395 5.343 5.343 5.343c2.949 0 5.344-2.394 5.344-5.343s-2.395-5.343-5.344-5.343zM16.105 13.306c1.475 0 2.672 1.197 2.672 2.671s-1.197 2.672-2.672 2.672c-1.474 0-2.671-1.197-2.671-2.672s1.197-2.671 2.671-2.671z"></path>
      </symbol>
      <symbol id="icon-filter" viewBox="0 0 32 32">
        <path d="M1.2 11.15h3.45c0.25 1.4 0.95 2.7 2.050 3.6s2.4 1.4 3.8 1.4c0.15 0 0.35 0 0.5 0 2.75-0.25 4.95-2.3 5.35-5h14.4c0.55 0 0.95-0.45 0.95-0.95s-0.45-0.95-0.95-0.95h-14.35c-0.5-3.050-3.25-5.25-6.4-5-2.75 0.25-4.95 2.3-5.35 5h-3.45c-0.55 0-0.95 0.45-0.95 0.95s0.45 0.95 0.95 0.95zM14.6 10.2c0 2.25-1.8 4.050-4.050 4.050 0 0 0 0 0 0-2.25 0-4.050-1.8-4.050-4.050 0-0.15 0-0.3 0-0.45 0.2-2.050 1.95-3.6 4.050-3.6 0 0 0 0 0 0 2.2 0 4.050 1.8 4.050 4.050z"></path>
        <path d="M30.8 20.85h-4.85c-0.25-1.4-0.95-2.7-2.050-3.6-1.2-1-2.75-1.5-4.35-1.35-2.75 0.25-4.95 2.3-5.35 5h-13c-0.55 0-0.95 0.45-0.95 0.95 0 0.55 0.45 0.95 0.95 0.95h12.95c0.25 1.4 0.95 2.7 2.050 3.6s2.4 1.4 3.8 1.4c0.15 0 0.35 0 0.5 0 2.75-0.25 4.95-2.3 5.35-5h4.85c0.55 0 0.95-0.45 0.95-0.95 0.1-0.6-0.35-1-0.85-1zM16 21.8c0-2.25 1.8-4.050 4.050-4.050 0 0 0 0 0 0 2.25 0 4.050 1.8 4.050 4.050 0 0.15 0 0.3 0 0.45-0.2 2.050-1.95 3.6-4 3.6 0 0 0 0 0 0-2.3 0-4.1-1.8-4.1-4.050z"></path>
      </symbol>
      <symbol id="icon-credit-card" viewBox="0 0 32 32">
        <path d="M28.35 3.25h-24.7c-2 0-3.65 1.65-3.65 3.65v18.15c0 2 1.65 3.65 3.65 3.65h24.65c2 0 3.65-1.65 3.65-3.65v-18.15c0.050-2-1.6-3.65-3.6-3.65zM3.65 6h24.65c0.5 0 0.95 0.4 0.95 0.95v3.2h-26.5v-3.2c0-0.55 0.4-0.95 0.9-0.95zM28.35 26h-24.7c-0.5 0-0.95-0.4-0.95-0.95v-12.15h26.5v12.2c0.050 0.5-0.35 0.9-0.85 0.9z"></path>
      </symbol>
      <symbol id="icon-wishlist-full" viewBox="0 0 32 32">
        <path d="M16 30.15c-0.35 0-0.7-0.15-1-0.4l-12.3-12.35c-1.75-1.8-2.7-4.050-2.7-6.5s0.95-4.7 2.7-6.4c1.7-1.7 4-2.65 6.4-2.65 0 0 0 0 0.050 0 2.4 0 4.7 0.95 6.4 2.7l0.5 0.5 0.5-0.5c1.7-1.7 4-2.65 6.4-2.65 0 0 0 0 0 0 2.4 0 4.7 0.95 6.4 2.65v0c1.7 1.7 2.65 4 2.65 6.4s-0.95 4.7-2.65 6.4l-12.35 12.4c-0.25 0.25-0.65 0.4-1 0.4z"></path>
      </symbol>
      <symbol id="icon-search" viewBox="0 0 32 32">
        <path d="M13.12 23.36c-4.765 0-8.64-3.875-8.64-8.64s3.875-8.64 8.64-8.64c4.765 0 8.64 3.875 8.64 8.64s-3.875 8.64-8.64 8.64zM30.4 29.964l-8.188-8.188c1.516-1.951 2.428-4.395 2.428-7.056 0-6.362-5.158-11.52-11.52-11.52s-11.52 5.158-11.52 11.52c0 6.362 5.158 11.52 11.52 11.52 2.663 0 5.105-0.912 7.056-2.428l8.188 8.188 2.036-2.036z"></path>
      </symbol>
      <symbol id="icon-pinterest" viewBox="0 0 32 32">
        <path d="M16 0c-8.836 0-16 7.163-16 16 0 6.779 4.217 12.568 10.169 14.899-0.14-1.265-0.267-3.207 0.056-4.588 0.291-1.249 1.876-7.953 1.876-7.953s-0.479-0.959-0.479-2.376c0-2.224 1.289-3.885 2.895-3.885 1.364 0 2.024 1.025 2.024 2.253 0 1.372-0.873 3.424-1.325 5.327-0.377 1.592 0.799 2.892 2.369 2.892 2.844 0 5.029-2.999 5.029-7.327 0-3.831-2.752-6.509-6.683-6.509-4.552 0-7.224 3.415-7.224 6.943 0 1.375 0.529 2.851 1.191 3.651 0.131 0.159 0.149 0.299 0.111 0.46l-0.444 1.813c-0.071 0.293-0.232 0.356-0.536 0.215-1.999-0.931-3.248-3.852-3.248-6.199 0-5.047 3.667-9.683 10.572-9.683 5.551 0 9.864 3.956 9.864 9.241 0 5.515-3.476 9.952-8.303 9.952-1.621 0-3.145-0.841-3.667-1.837l-0.997 3.804c-0.361 1.391-1.336 3.133-1.989 4.195 1.499 0.463 3.089 0.713 4.739 0.713 8.836 0 16-7.164 16-16 0-8.837-7.164-16-16-16z"></path>
      </symbol>
    </defs>
  </svg>
);
