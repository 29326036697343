import './AssembledComponentSkuTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { ProductTileStickers } from './ProductTileStickers';
import { QuantityInputGroup } from './QuantityInputGroup';

export const AssembledComponentSkuTile = (props: b2x.AssembledComponentSkuTileProps) => (
  <b2x.AssembledComponentSkuTile {...props} customComponent={_AssembledComponentSkuTile} />
);

export const _AssembledComponentSkuTile = ({ assembledProduct, componentSku }: b2x.AssembledComponentSkuTileProps) => {
  React.useEffect(() => {
    console.log('componentSku', componentSku);
  }, [componentSku]);

  const product = componentSku.sku?.product;
  const sku = componentSku.sku;

  if (product === undefined) {
    throw new Error(`Product undefined for componentSku: ${componentSku.code}`);
  }

  if (sku === undefined) {
    throw new Error(`Sku undefined for componentSku: ${componentSku.code}`);
  }

  product.skus = [sku];

  const priceHelper = b2x.usePrice(undefined, componentSku.price, { multiplier: componentSku.multiplier });
  const priceHelperWithoutMultiplier = b2x.usePrice(undefined, componentSku.price);

  return (
    <div className="product-tile card border-0">
      <div className="product-tile-basic-info">
        <div className="product-tile-image-container position-relative mb-3 bg-gray-100">
          <b2x.Image
            alt={product.name}
            aspectRatio={b2x.appConfig.productImageAspectRatio}
            className="d-block"
            fluid
            // format={imageFormat}
            src={sku.image ? sku.image.src : product.image?.src}
          />
          <ProductTileStickers fixedTags={assembledProduct.tags} product={product} />
          {priceHelper.discountPercentage && (
            <div className="discount-percentage position-absolute top-0 start-0 py-1 py-md-2">
              <span className="bg-red text-white fw-bold lh-1 py-1 ps-3 pe-2 rounded-end">{`-${b2x.formatFloatPercentage(
                priceHelper.discountPercentage
              )}%`}</span>
            </div>
          )}
          {componentSku.multiplier > 1 && (
            <div className="position-absolute bottom-0 start-0 p-2">
              <span className="h3 primary-font fw-semi-bold">x</span>
              <span className="h1 primary-font fw-semi-bold">{componentSku.multiplier}</span>
            </div>
          )}
        </div>
        <div className="mb-2">
          <h4 className="m-0 mb-1 product-title">
            <span className="text-blue fw-bold">{b2x.formatHtml(product.name)}</span>
          </h4>
          {sku.name !== product.name && (
            <div className="mb-0 text-blue m-0">
              {componentSku.multiplier > 1 && <span className="pe-1 fw-bold">{componentSku.multiplier} x</span>}
              <span className="extra-small fw-semi-bold">{sku.name}</span>
            </div>
          )}
        </div>
      </div>
      <div className="product-tile-extra-info">
        <div className={classnames('mb-1')}>
          <div className="hstack flex-wrap">
            <div className="me-2">
              <b2x.PriceBlock
                classNames={{ specialPrice: 'fw-bold text-red' }}
                gap={2}
                hiddenDiscountPercentage
                priceHelper={priceHelper}
              />
            </div>
            {priceHelper.perUnit?.price && (
              <div className="extra-small">
                {`(${t('misc.pricePerUnit', {
                  measurementUnit: priceHelper.perUnit.measurement,
                  price: b2x.formatCurrency(priceHelper.perUnit.price),
                })})`}
              </div>
            )}
          </div>
          {componentSku.multiplier > 1 && (
            <div className="text-muted extra-small mb-2">
              (
              {t('misc.pricePerShape', {
                price: b2x.formatCurrency(priceHelperWithoutMultiplier.price),
                shape: componentSku.sku?.shape?.toLowerCase(),
              })}
              )
            </div>
          )}
        </div>

        {sku.state === 'AVAILABLE' ? (
          <b2x.Div display="flex" justifyContent={{ lg: 'end', xs: 'start' }}>
            <QuantityInputGroup
              decreaseButton={{ disabled: !componentSku.removeEnabled, onClick: componentSku.removeQuantity }}
              increaseButton={{ disabled: !componentSku.addEnabled, onClick: componentSku.addQuantity }}
              quantity={componentSku.quantity}
              variant="primary"
            />
          </b2x.Div>
        ) : (
          <div className="d-grid my-2">
            <Button className="btn-sm" disabled label={t('misc.productNotAvailable')} variant="outline-primary" />
          </div>
        )}
        {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
          <div className="extra-small lh-1 mt-2">
            <b2x.BestPrice priceHelper={priceHelper} />
          </div>
        )}
      </div>
    </div>
  );
};
