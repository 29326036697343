import { b2x } from '@b2x/react/src';

import { ButtonVariant } from './Button';
import { IconName, IconSize } from './Icon';

export const QuantityInputGroup = ({
  size = 40,
  variant = 'gray-200',
  ...otherProps
}: b2x.QuantityInputGroupProps<ButtonVariant, IconName, IconSize>) => (
  <b2x.QuantityInputGroup {...otherProps} customComponent={CustomQuantityInputGroup} size={size} variant={variant} />
);

const CustomQuantityInputGroup = ({
  decreaseButton,
  increaseButton,
  quantity,
  size,

  variant,
}: b2x.QuantityInputGroupProps<ButtonVariant, IconName, IconSize>) => (
  <b2x.Div alignItems="stretch" display="flex" style={{ height: size }}>
    <b2x.Button
      {...decreaseButton}
      className="p-0"
      iconStart={{ name: 'minus', size: 14 }}
      label={undefined}
      style={{ width: size }}
      variant={variant}
    />
    <input
      className="form-control border-0 text-center fw-bold bg-transparent p-0"
      readOnly
      style={{ width: 45 }}
      type="text"
      value={quantity}
    />
    <b2x.Button
      {...increaseButton}
      className="p-0"
      iconStart={{ name: 'plus', size: 14 }}
      label={undefined}
      style={{ width: size }}
      variant={variant}
    />
  </b2x.Div>
);
