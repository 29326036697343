import { ButtonProps } from './Button';
import { formikNumber } from './form/Form';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface QuantityInputGroupProps<ButtonVariant, IconName, IconSize> {
  decreaseButton: ButtonProps<ButtonVariant, IconName, IconSize>;
  increaseButton: ButtonProps<ButtonVariant, IconName, IconSize>;
  quantity: formikNumber;
  size?: number;
  variant?: ButtonVariant;
}

const QuantityInputGroup = <ButtonVariant extends string, IconName extends string, IconSize extends number>(
  props: QuantityInputGroupProps<ButtonVariant, IconName, IconSize>
) => {
  return <>TO BE OVERRIDDEN</>;
};

export type QuantityInputGroupVariants = '';

const QuantityInputGroupController = <ButtonVariant extends string, IconName extends string, IconSize extends number>(
  props: PropsWithCustomComponent<QuantityInputGroupProps<ButtonVariant, IconName, IconSize>>
) => (
  <VariantsController<QuantityInputGroupProps<ButtonVariant, IconName, IconSize>, QuantityInputGroupVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: QuantityInputGroup,
      name: 'QuantityInputGroup',
    }}
  />
);
export { QuantityInputGroupController as QuantityInputGroup };
