import './ProductAssembler.structure.scss';

import classnames from 'classnames';
import React from 'react';
import SwiperInterface from 'swiper';

import { Accordion, AccordionItem } from './Accordion';
import { useAppContext } from './AppContext';
import { AssembledComponentSkuTile } from './AssembledComponentSkuTile';
import { Badge } from './Badge';
import { Button } from './Button';
import { CircleProgressBar } from './CircleProgressBar';
import { Col } from './Col';
import { appConfig } from './config';
import { Div, P, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { PriceBlock } from './PriceBlock';
import { ProgressBar } from './ProgressBar';
import { QuantityInputGroup } from './QuantityInputGroup';
import { Row } from './Row';
import { Swiper } from './Swiper';
import { SwiperContext } from './SwiperContext';
import { usePrice } from './usePrice';
import {
  AssembledComponent,
  AssembledComponentSku,
  AssembledProduct,
  useProductAssembler,
} from './useProductAssembler';
import { decodeHtml, formatCurrency, getProductShippingCost, scrollTop, uiClassName } from './util';

export interface ProductAssemblerProps {
  lonelySkuAutomaticAddition?: boolean;
  maxDiscountPercentageAttributeTypeCode?: string;
  productId: string;
}

export const ProductAssembler = ({
  lonelySkuAutomaticAddition,
  maxDiscountPercentageAttributeTypeCode,
  productId,
}: ProductAssemblerProps) => {
  const { addToCart, assembledProduct, product } = useProductAssembler({ lonelySkuAutomaticAddition, productId });

  const [step, setStep] = React.useState<number>(1);

  const maxDiscountPercentage = React.useMemo(
    () =>
      product?.attributes?.find((attribute) => attribute.typeCode === maxDiscountPercentageAttributeTypeCode)?.value,
    [maxDiscountPercentageAttributeTypeCode, product?.attributes]
  );

  const currentAssembledComponent = React.useMemo(
    () => assembledProduct?.assembledComponents?.at(step - 1),
    [assembledProduct?.assembledComponents, step]
  );

  const setNextStepButtonClick = React.useCallback(() => {
    assembledProduct?.assembledComponents &&
      step < assembledProduct.assembledComponents.length &&
      currentAssembledComponent?.completed &&
      setStep((prevState) => prevState + 1);
  }, [assembledProduct?.assembledComponents, currentAssembledComponent?.completed, step]);

  const setPrevStepButtonClick = React.useCallback(() => {
    step > 1 && setStep((prevState) => prevState - 1);
  }, [step]);

  const firstStep = step === 1;
  const lastStep = step === (assembledProduct?.assembledComponents && assembledProduct.assembledComponents.length);

  const freeShipping = React.useMemo<boolean>(() => product?.tags?.includes('FREE_SHIPPING') ?? false, [product?.tags]);

  const { shippingCountry } = useAppContext();

  const totalSavings = React.useMemo<number | undefined>(() => {
    if (assembledProduct?.totalDiscount !== undefined) {
      if (appConfig.includeFreeShippingInSavingAmount && product && shippingCountry) {
        const shippingCost = getProductShippingCost(product, shippingCountry.code);
        return assembledProduct.totalDiscount + (shippingCost ?? 0);
      } else {
        return assembledProduct.totalDiscount;
      }
    }
    return undefined;
  }, [assembledProduct?.totalDiscount, product, shippingCountry]);

  return (
    <>
      {product && assembledProduct && (
        <Div className="product-assembler" style={{ position: 'relative' }}>
          <Steps assembledProduct={assembledProduct} setStep={setStep} step={step} />
          <Row>
            <Col size={{ lg: 8, xs: 12 }}>
              <Div
                alignItems="center"
                className={uiClassName({
                  all: 'assembled-product-info-wrapper',
                  bs5: 'sticky-lg-top',
                })}
                display="flex"
                justifyContent="spaceBetween"
                marginBottom={{ lg: 5, xs: 4 }}
                padding={{ xs: 3 }}
              >
                <Div className={classnames('assembled-product-info')}>
                  <Div className="name" marginBottom={0}>
                    {product.name}
                  </Div>
                  <Div className="components">
                    <Span textTransform="uppercase">
                      {t('assembledProduct.totalPieces', { total: assembledProduct.minQty })}
                    </Span>{' '}
                    (
                    {assembledProduct.assembledComponents
                      ?.map((assembledComponent) => `${decodeHtml(assembledComponent.name)}`)
                      .join(' - ')}
                    )
                  </Div>
                  <Div className="badges" display={{ lg: 'flex', xs: 'none' }} gap={3} marginTop={2}>
                    {maxDiscountPercentage && (
                      <Badge className="discount-badge">
                        {appConfig.icons?.discount && <Icon name={appConfig.icons.discount} />}
                        {` ${t('assembledProduct.discountUpTo', { percentage: maxDiscountPercentage })}`}
                      </Badge>
                    )}
                    {freeShipping && (
                      <Badge className="free-shipping-badge">
                        {appConfig.icons?.shipping && <Icon name={appConfig.icons.shipping} />}
                        {` ${t('assembledProduct.freeShipping')}`}
                      </Badge>
                    )}
                  </Div>
                </Div>
                {currentAssembledComponent && (
                  <Div display={{ lg: 'block', xs: 'none' }}>
                    <CircleProgressBar
                      size={100}
                      strokeLineCap="square"
                      strokeWidth={20}
                      valueCurrent={currentAssembledComponent.curQty}
                      valueFrom={currentAssembledComponent.curQty}
                      valueTo={currentAssembledComponent.maxQty}
                    />
                  </Div>
                )}
              </Div>

              {/* <EqualHeight> */}
              <Row cols={{ lg: 3, xs: 2 }} gapX={{ lg: 4, xs: 3 }} gapY={{ lg: 5, xs: 4 }}>
                {currentAssembledComponent?.componentSkus?.map((componentSku) => (
                  <Col key={componentSku.code}>
                    <AssembledComponentSkuTile assembledProduct={product} componentSku={componentSku} />
                  </Col>
                ))}
              </Row>
              {/* </EqualHeight> */}
            </Col>
            <Col display={{ lg: 'block', xs: 'none' }} size={{ lg: 4 }}>
              <DesktopSummary
                addToCart={addToCart}
                assembledProduct={assembledProduct}
                currentAssembledComponent={currentAssembledComponent}
                firstStep={firstStep}
                freeShipping={freeShipping}
                lastStep={lastStep}
                setNextStepButtonClick={setNextStepButtonClick}
                setPrevStepButtonClick={setPrevStepButtonClick}
                step={step}
                totalSavings={totalSavings}
              />
            </Col>
          </Row>
          <MobileSummary
            addToCart={addToCart}
            assembledProduct={assembledProduct}
            currentAssembledComponent={currentAssembledComponent}
            firstStep={firstStep}
            freeShipping={freeShipping}
            lastStep={lastStep}
            setNextStepButtonClick={setNextStepButtonClick}
            setPrevStepButtonClick={setPrevStepButtonClick}
            step={step}
            totalSavings={totalSavings}
          />
        </Div>
      )}
    </>
  );
};

interface StepsProps {
  assembledProduct: AssembledProduct;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

const Steps = ({ assembledProduct, setStep, step }: StepsProps) => {
  const circleSize = 40;

  const [swiper, setSwiper] = React.useState<SwiperInterface>();
  React.useEffect(() => {
    swiper && swiper.slideTo(step - 1);
  }, [swiper, step]);

  return (
    <Div className="steps" marginY={{ lg: 5, xs: 3 }}>
      <SwiperContext>
        <Swiper
          breakpoints={{
            lg: {
              slidesPerView: assembledProduct.assembledComponents?.length,
            },
          }}
          initialSlide={step - 1}
          onSwiper={setSwiper}
          slides={assembledProduct.assembledComponents?.map((assembledComponent, index, array) => (
            <Div
              alignItems="center"
              className={classnames(
                'step',
                { first: index === 0 },
                { last: index === array.length - 1 },
                { behind: index + 1 < step },
                { current: index + 1 === step },
                { ahead: index + 1 > step }
              )}
              display="flex"
              flexDirection="column"
              key={assembledComponent.id}
              style={{ flex: 1 }}
            >
              <Div className="circle-wrapper" marginBottom={3}>
                <Div
                  alignItems="center"
                  className={classnames('circle', uiClassName({ bs5: 'rounded-circle border border-2' }))}
                  display="flex"
                  justifyContent="center"
                  style={{ height: circleSize, width: circleSize }}
                >
                  {/* <BlankButton
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => {
                      setStep(index + 1);
                    }}
                  > */}
                  {index + 1}
                  {/* </BlankButton> */}
                </Div>
              </Div>
              <Div className="text">{decodeHtml(assembledComponent.name)}</Div>
            </Div>
          ))}
          slidesPerView={1}
        />
      </SwiperContext>
    </Div>
  );
};

interface SummaryProps {
  addToCart(): void;
  assembledProduct: AssembledProduct | undefined;
  currentAssembledComponent: AssembledComponent | undefined;
  firstStep: boolean;
  freeShipping: boolean;
  lastStep: boolean;
  setNextStepButtonClick(): void;
  setPrevStepButtonClick(): void;
  step: number;
  totalSavings?: number;
}

const DesktopSummary = ({
  addToCart,
  assembledProduct,
  currentAssembledComponent,
  firstStep,
  freeShipping,
  lastStep,
  setNextStepButtonClick,
  setPrevStepButtonClick,
  step,
  totalSavings,
}: SummaryProps) => {
  return (
    <Div className="desktop-summary" display={'flex'} flexDirection={'column'} gap={3} padding={4}>
      <Div className="summary-title">
        <P margin={0}>{t('assembledProduct.summary.title')}</P>
      </Div>
      <Div className="summary-bedge">
        <Div className="badges" display={'flex'} flexDirection={'column'} gap={1}>
          {totalSavings && totalSavings !== 0 ? (
            <Badge className="discount-badge">
              {appConfig.icons?.discount && <Icon name={appConfig.icons.discount} />}
              {` ${t('assembledProduct.summary.badges.totalDiscount', {
                value: formatCurrency(totalSavings),
              })}`}
            </Badge>
          ) : undefined}
          {freeShipping && (
            <Badge className="free-shipping-badge">
              {appConfig.icons?.shipping && <Icon name={appConfig.icons.shipping} />}
              {` ${t('assembledProduct.summary.badges.freeShipping')}`}
            </Badge>
          )}
        </Div>
      </Div>
      <Div className="summary-step">
        <Accordion alwaysOpened id="summary-step-accordion">
          {assembledProduct?.assembledComponents?.map((assembledComponent, index) => {
            const assembledComponentAdded = assembledComponent.componentSkus?.filter(
              (componentSku) => componentSku.quantity > 0
            );

            const disabled = step <= index && assembledComponent.curQty === 0;

            return (
              <AccordionItem
                disabled={disabled}
                id={`summary-step-item-${index}`}
                key={assembledComponent.code}
                show={!disabled && index + 1 === step}
                title={
                  <Div
                    className="summary-step-title"
                    display={'flex'}
                    justifyContent={'spaceBetween'}
                    paddingEnd={2}
                    style={{ width: '100%' }}
                  >
                    <Span className="title">{decodeHtml(assembledComponent.name)}</Span>
                    <Span className="counter">
                      ({assembledComponent.curQty}/{assembledComponent.maxQty})
                    </Span>
                  </Div>
                }
              >
                {assembledComponentAdded && assembledComponentAdded.length > 0 ? (
                  <Div className="summary-items" display={'flex'} flexDirection={'column'} gap={3}>
                    {assembledComponentAdded.map((componentSku) => (
                      <SummaryItem componentSku={componentSku} key={componentSku.code} last={false} />
                    ))}
                  </Div>
                ) : (
                  <Div className="summary-items-info-message">
                    {t('assembledProduct.summary.infoMessage.addAtLeast', { count: assembledComponent.minQty })}
                  </Div>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
      </Div>
      <Div className="summary-total">
        <Row justifyContent={'spaceBetween'}>
          <Col size={'auto'}>{t('assembledProduct.summary.total')}</Col>
          <Col size={'auto'}>{assembledProduct && formatCurrency(assembledProduct.totalPrice)}</Col>
        </Row>
      </Div>
      <Div className="summary-action">
        <Row gap={3}>
          {!firstStep && (
            <Col size={6}>
              <Div className="prev-button-container">
                <Button
                  disabled={firstStep}
                  label={t('assembledProduct.summary.actions.previous')}
                  onClick={setPrevStepButtonClick}
                />
              </Div>
            </Col>
          )}
          <Col size={firstStep ? 12 : 6}>
            <Div className="next-button-container">
              <Button
                className="next-button"
                disabled={lastStep ? !assembledProduct?.completed : !currentAssembledComponent?.completed}
                label={
                  lastStep
                    ? t('assembledProduct.summary.actions.addToCart')
                    : t('assembledProduct.summary.actions.next')
                }
                onClick={lastStep ? addToCart : setNextStepButtonClick}
              />
            </Div>
          </Col>
          {assembledProduct && !assembledProduct.completed && currentAssembledComponent && (
            <Col size={12}>
              <SummaryActionInfoMessage currentAssembledComponent={currentAssembledComponent} lastStep={lastStep} />
            </Col>
          )}
        </Row>
      </Div>
    </Div>
  );
};

const MobileSummary = ({
  addToCart,
  assembledProduct,
  currentAssembledComponent,
  firstStep,
  freeShipping,
  lastStep,
  setNextStepButtonClick,
  setPrevStepButtonClick,
  step,
  totalSavings,
}: SummaryProps) => {
  const mobileSummaryRef = React.useRef<HTMLElement>(null);

  const toggleMobileSummaryButtonClick = React.useCallback(() => {
    mobileSummaryRef.current?.classList.toggle('active');
  }, []);

  const setPrevStepMobileButtonClick = React.useCallback(() => {
    if (mobileSummaryRef.current) {
      if (mobileSummaryRef.current.classList.contains('active')) {
        toggleMobileSummaryButtonClick();
      }
    }
    if (!firstStep) {
      setPrevStepButtonClick();
      scrollTop();
    }
  }, [firstStep, setPrevStepButtonClick, toggleMobileSummaryButtonClick]);

  const setNextStepMobileButtonClick = React.useCallback(() => {
    if (mobileSummaryRef.current) {
      if (mobileSummaryRef.current.classList.contains('active')) {
        toggleMobileSummaryButtonClick();
      }
    }
    setNextStepButtonClick();
    scrollTop();
  }, [setNextStepButtonClick, toggleMobileSummaryButtonClick]);

  return (
    <Div className="mobile-summary" display={{ lg: 'none', xs: 'block' }}>
      <Div className="summary-main-action">
        <Div className="toggle-offcanvas-button-container">
          <Button onClick={toggleMobileSummaryButtonClick}>
            <Div display="flex" flexDirection="column" gap={1} style={{ width: '100%' }}>
              <Div alignItems={'center'} className="w-100" display={'flex'} justifyContent={'spaceBetween'}>
                <Span className="icon" style={{ visibility: 'hidden' }} textAlign={'end'}>
                  {appConfig.icons?.arrow?.top && <Icon name={appConfig.icons.arrow.top} />}
                </Span>
                <Span className="label">{t('assembledProduct.summary.title')}</Span>
                <Span className="icon" textAlign={'end'}>
                  {appConfig.icons?.arrow?.top && <Icon name={appConfig.icons.arrow.top} />}
                </Span>
              </Div>
              <Div className="component-info" textAlign="start">
                <Span className="counter" paddingEnd={2}>
                  ({currentAssembledComponent?.curQty}/{currentAssembledComponent?.maxQty})
                </Span>
                <Span className="name">{decodeHtml(currentAssembledComponent?.name)}</Span>
              </Div>
              {totalSavings && totalSavings !== 0 ? (
                <Badge className="discount-badge">
                  {appConfig.icons?.discount && <Icon name={appConfig.icons.discount} />}
                  {` ${t('assembledProduct.summary.badges.totalDiscount', {
                    value: formatCurrency(totalSavings),
                  })}`}
                </Badge>
              ) : undefined}
            </Div>
          </Button>
          {currentAssembledComponent && currentAssembledComponent.curQty > 0 ? (
            <ProgressBar
              current={currentAssembledComponent.curQty}
              hiddenCount
              max={currentAssembledComponent.maxQty}
              min={currentAssembledComponent.minQty}
            />
          ) : undefined}
        </Div>
        {firstStep ? (
          currentAssembledComponent?.completed && (
            <Div className="navigation-button-container" padding={2}>
              <Button
                label={
                  lastStep
                    ? t('assembledProduct.summary.actions.addToCart')
                    : t('assembledProduct.summary.actions.next')
                }
                onClick={lastStep ? addToCart : setNextStepMobileButtonClick}
              />
            </Div>
          )
        ) : (
          <Div className="navigation-button-container" padding={2}>
            <Row gap={2}>
              <Col size={6}>
                <Div className="prev-button-container">
                  <Button
                    label={t('assembledProduct.summary.actions.previous')}
                    onClick={setPrevStepMobileButtonClick}
                  />
                </Div>
              </Col>
              <Col size={6}>
                <Div className="next-button-container">
                  <Button
                    className="next-button"
                    disabled={lastStep ? !assembledProduct?.completed : !currentAssembledComponent?.completed}
                    label={
                      lastStep
                        ? t('assembledProduct.summary.actions.addToCart')
                        : t('assembledProduct.summary.actions.next')
                    }
                    onClick={lastStep ? addToCart : setNextStepMobileButtonClick}
                  />
                </Div>
              </Col>
            </Row>
          </Div>
        )}
      </Div>
      <Div className="summary-offcanvas" innerRef={mobileSummaryRef}>
        <Div className="summary-container">
          <Div className="summary-inner-container">
            <Div className="summary-header">
              <Button onClick={toggleMobileSummaryButtonClick}>
                <Div className="w-100" display={'flex'} justifyContent={'spaceBetween'}>
                  <Span className="counter" textAlign={'start'}>
                    ({currentAssembledComponent?.curQty}/{currentAssembledComponent?.maxQty})
                  </Span>
                  <Span className="label">{t('assembledProduct.summary.title')}</Span>
                  <Span className="icon" textAlign={'end'}>
                    {appConfig.icons?.arrow?.bottom && <Icon name={appConfig.icons.arrow.bottom} />}
                  </Span>
                </Div>
              </Button>
              {currentAssembledComponent && currentAssembledComponent.curQty > 0 ? (
                <ProgressBar
                  current={currentAssembledComponent.curQty}
                  hiddenCount
                  max={currentAssembledComponent.maxQty}
                  min={currentAssembledComponent.minQty}
                />
              ) : undefined}
            </Div>
            <Div display={'flex'} flexDirection={'column'} gap={3} padding={3}>
              <Div className="summary-bedge">
                <Div className="badges" display={'flex'} flexDirection={'column'} gap={1}>
                  {totalSavings && totalSavings !== 0 ? (
                    <Badge className="discount-badge">
                      {appConfig.icons?.discount && <Icon name={appConfig.icons.discount} />}
                      {` ${t('assembledProduct.summary.badges.totalDiscount', {
                        value: formatCurrency(totalSavings),
                      })}`}
                    </Badge>
                  ) : undefined}
                  {freeShipping && (
                    <Badge className="free-shipping-badge">
                      {appConfig.icons?.shipping && <Icon name={appConfig.icons.shipping} />}
                      {` ${t('assembledProduct.freeShipping')}`}
                    </Badge>
                  )}
                </Div>
              </Div>
              <Div className="summary-step">
                <Accordion alwaysOpened id="summary-step-accordion">
                  {assembledProduct?.assembledComponents?.map((assembledComponent, index) => {
                    const assembledComponentAdded = assembledComponent.componentSkus?.filter(
                      (componentSku) => componentSku.quantity > 0
                    );

                    const disabled = step <= index && assembledComponent.curQty === 0;

                    return (
                      <AccordionItem
                        disabled={disabled}
                        id={`summary-step-item-${index}`}
                        key={assembledComponent.code}
                        show={!disabled && index + 1 === step}
                        title={
                          <Div
                            className="summary-step-title"
                            display={'flex'}
                            justifyContent={'spaceBetween'}
                            paddingEnd={2}
                            style={{ width: '100%' }}
                          >
                            <Span className="title">{decodeHtml(assembledComponent.name)}</Span>
                            <Span className="counter">
                              ({assembledComponent.curQty}/{assembledComponent.maxQty})
                            </Span>
                          </Div>
                        }
                      >
                        <Div display="flex" justifyContent="center" marginY={3}>
                          <CircleProgressBar
                            size={200}
                            strokeLineCap="square"
                            strokeWidth={20}
                            valueCurrent={assembledComponent.curQty}
                            valueFrom={assembledComponent.curQty}
                            valueTo={assembledComponent.maxQty}
                          />
                        </Div>
                        {assembledComponentAdded && assembledComponentAdded.length > 0 ? (
                          <Div className="summary-items" display={'flex'} flexDirection={'column'} gap={3}>
                            {assembledComponentAdded.map((componentSku) => (
                              <SummaryItem componentSku={componentSku} key={componentSku.code} last={false} />
                            ))}
                          </Div>
                        ) : (
                          <Div className="summary-items-info-message">
                            {t('assembledProduct.summary.infoMessage.addAtLeast', { count: assembledComponent.minQty })}
                          </Div>
                        )}
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </Div>
              <Div className="summary-total">
                <Row justifyContent={'spaceBetween'}>
                  <Col size={'auto'}>{t('assembledProduct.summary.total')}</Col>
                  <Col size={'auto'}>{assembledProduct && formatCurrency(assembledProduct.totalPrice)}</Col>
                </Row>
              </Div>
              <Div className="summary-action">
                <Row gap={3}>
                  {!firstStep && (
                    <Col size={6}>
                      <Div className="prev-button-container">
                        <Button
                          label={t('assembledProduct.summary.actions.previous')}
                          onClick={setPrevStepMobileButtonClick}
                        />
                      </Div>
                    </Col>
                  )}
                  <Col size={firstStep ? 12 : 6}>
                    <Div className="next-button-container">
                      <Button
                        className="next-button"
                        disabled={lastStep ? !assembledProduct?.completed : !currentAssembledComponent?.completed}
                        label={
                          lastStep
                            ? t('assembledProduct.summary.actions.addToCart')
                            : t('assembledProduct.summary.actions.next')
                        }
                        onClick={lastStep ? addToCart : setNextStepMobileButtonClick}
                      />
                    </Div>
                  </Col>
                  {assembledProduct && !assembledProduct.completed && currentAssembledComponent && (
                    <Col size={12}>
                      <SummaryActionInfoMessage
                        currentAssembledComponent={currentAssembledComponent}
                        lastStep={lastStep}
                      />
                    </Col>
                  )}
                </Row>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

interface SummaryActionInfoMessageProps {
  currentAssembledComponent: AssembledComponent;
  lastStep: boolean;
}

const SummaryActionInfoMessage = ({ currentAssembledComponent, lastStep }: SummaryActionInfoMessageProps) => {
  return (
    <Div className="summary-action-info-message">
      {!currentAssembledComponent.completed
        ? currentAssembledComponent.curQty === 0
          ? t('assembledProduct.summary.infoMessage.completeStepToContinue')
          : t('assembledProduct.summary.infoMessage.addMoreProducts', {
              count: Number(currentAssembledComponent.minQty - currentAssembledComponent.curQty),
            })
        : lastStep && t('assembledProduct.summary.infoMessage.completeAllStepToContinue')}
    </Div>
  );
};

interface SummaryItemProps {
  componentSku: AssembledComponentSku;
  last: boolean;
}

const SummaryItem = ({ componentSku, last }: SummaryItemProps) => {
  const product = componentSku.sku?.product;
  const sku = componentSku.sku;

  if (product === undefined) {
    throw new Error(`Product undefined for componentSku: ${componentSku.code}`);
  }

  if (sku === undefined) {
    throw new Error(`Sku undefined for componentSku: ${componentSku.code}`);
  }

  product.skus = [sku];

  const priceHelper = usePrice(undefined, componentSku.price, {
    multiplier: componentSku.multiplier * componentSku.quantity,
  });

  return (
    <Div className="summary-item">
      <Row gap={3}>
        <Col size={''}>
          <P className="summary-item-name" margin={0}>
            {componentSku.sku?.product?.name}
          </P>
          <P className="summary-item-sku-name" margin={0}>
            {componentSku.multiplier > 1 && `${componentSku.multiplier} x`} {componentSku.sku?.name}
          </P>
          <Button
            className="summary-item-remove"
            label={t('assembledProduct.removeFromAssembly')}
            onClick={componentSku.remove}
            size="small"
            variant="blank"
          />
        </Col>
        <Col size={'auto'}>
          <Div className="summary-item-quantity">
            <QuantityInputGroup
              decreaseButton={{ disabled: !componentSku.removeEnabled, onClick: componentSku.removeQuantity }}
              increaseButton={{ disabled: !componentSku.addEnabled, onClick: componentSku.addQuantity }}
              quantity={componentSku.quantity}
            />
          </Div>
          <Div className="summary-item-price">
            <PriceBlock gap={2} hiddenDiscountPercentage priceHelper={priceHelper} />
          </Div>
        </Col>
      </Row>
    </Div>
  );
};
