import './BoxE.scss';

import { b2x } from '@b2x/react/src';

import { BoxEContentSection } from './contentTypes';
import { Icon, isIconName } from './Icon';

export interface BoxEProps extends BoxEContentSection {}

export const BoxE = ({ backgroundColor, copy, image }: BoxEProps) => {
  return (
    <b2x.Div className="box-e overflow-hidden rounded-3">
      <b2x.Div className="position-relative">
        <b2x.ImageFromContentV2 className="d-none d-lg-block" {...image} />
        {(copy?.title || copy?.content) && (
          <b2x.Div
            background={backgroundColor}
            className="copy-container px-3 py-4 pt-lg-5"
            style={{ color: copy.color }}
          >
            {copy.title && (
              <b2x.Div className="title mb-2 text-uppercase fw-bold">{b2x.formatHtml(copy.title)}</b2x.Div>
            )}
            {copy.content && (
              <b2x.Div className="content text-red m-auto bg-white py-1 px-3 fw-bold rounded-1 mb-2" display={'table'}>
                {b2x.formatHtml(copy.content)}
              </b2x.Div>
            )}
            {copy.labelWithIcon?.label && (
              <b2x.Div className="label-with-icon fw-bold">
                {copy.labelWithIcon.icon && isIconName(copy.labelWithIcon.icon) && (
                  <Icon className="me-2" name={copy.labelWithIcon.icon} />
                )}
                {copy.labelWithIcon.label}
              </b2x.Div>
            )}
          </b2x.Div>
        )}
      </b2x.Div>
    </b2x.Div>
  );
};
