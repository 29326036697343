import { b2x } from '@b2x/react/src';

import { BoxA } from './BoxA';
import { BoxC } from './BoxC';
import { SectionBContentSection } from './contentTypes';

export interface SectionBProps extends SectionBContentSection {}

export const SectionB = ({ bottomLeftBox, bottomRightBox, title, topSlider }: SectionBProps) => {
  return (
    <section className="my-5">
      <b2x.Container>
        <h1 className="text-center text-uppercase fw-bold my-4">{title}</h1>
        <b2x.EqualHeight>
          <b2x.SwiperContext>
            <b2x.SwiperFromContent
              {...topSlider}
              breakpoints={{
                lg: {
                  slidesPerView: 3,
                },
                md: {
                  slidesPerView: 2.2,
                },
                sm: {
                  slidesPerView: 1.5,
                },
              }}
              className="mb-4"
              slides={topSlider?.slides?.map(({ contentSectionId, slide }) => (
                <b2x.EqualHeightElement key={contentSectionId} name={'box-a'}>
                  <BoxA {...slide} className="h-100" />
                </b2x.EqualHeightElement>
              ))}
              slidesPerView={1.2}
              spaceBetween={20}
            />
          </b2x.SwiperContext>
        </b2x.EqualHeight>
        <b2x.EqualHeight>
          <b2x.Row>
            <b2x.Col className="mb-lg-0 mb-4" size={{ md: 6 }}>
              <b2x.EqualHeightElement name="bottom">
                <BoxC {...bottomLeftBox} className="h-100" />
              </b2x.EqualHeightElement>
            </b2x.Col>
            <b2x.Col className="mb-lg-0 mb-4" size={{ md: 6 }}>
              <b2x.EqualHeightElement name="bottom">
                <BoxA {...bottomRightBox} className="h-100" />
              </b2x.EqualHeightElement>
            </b2x.Col>
          </b2x.Row>
        </b2x.EqualHeight>
      </b2x.Container>
    </section>
  );
};
