import React from 'react';

import { useTranslationsApi } from './api/useTranslationsApi';
import { useAppContext } from './AppContext';
import { Locale } from './interfaces';

export interface UseTranslationsProps {
  targetLocale?: Locale;
  texts: Array<string | undefined>;
}

export interface UseTranslationsResult {
  activeText: ActiveText;
  disabled: boolean;
  fetching: boolean;
  result: Array<string | undefined> | undefined;
  toggle(): void;
}

type ActiveText = 'original' | 'translated';

export const useTranslations = ({ targetLocale, texts }: UseTranslationsProps): UseTranslationsResult => {
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [activeText, setActiveText] = React.useState<ActiveText>('original');
  const [translatedTexts, setTranslatedTexts] = React.useState<Array<string | undefined>>();

  const { translateTexts } = useTranslationsApi();

  const { locale } = useAppContext();

  const disabled = targetLocale === undefined || targetLocale.languageCode === locale?.languageCode;

  const toggle = React.useCallback(() => {
    if (disabled) {
      return;
    } else {
      if (activeText === 'original') {
        if (!translatedTexts) {
          setFetching(true);
          translateTexts(
            texts.map((text) => text ?? ''),
            targetLocale,
            { silent: true }
          ).then((apiResult) => {
            setFetching(false);
            setTranslatedTexts(apiResult.data);
            setActiveText('translated');
          });
        } else {
          setActiveText('translated');
        }
      } else {
        setActiveText('original');
      }
    }
  }, [activeText, disabled, targetLocale, texts, translateTexts, translatedTexts]);

  const result = React.useMemo(
    () => (activeText === 'original' ? texts : translatedTexts),
    [activeText, texts, translatedTexts]
  );

  return { activeText, disabled, fetching, result, toggle };
};
