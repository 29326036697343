import { useTranslations, UseTranslationsProps, UseTranslationsResult } from './useTranslations';

export interface TranslationsProps extends UseTranslationsProps {
  children(props: UseTranslationsResult): React.ReactNode;
}

export const Translations = ({ children, ...otherProps }: TranslationsProps) => {
  const useTranslationsResult = useTranslations(otherProps);

  return <>{children(useTranslationsResult)}</>;
};
