import { b2x } from '@b2x/react/src';

import { ErrorPageContentType } from '../contentTypes';
import { MessageBoxPage } from './MessageBoxPage';
export interface ErrorPageProps {
  contentCode: string;
}

export const ErrorPage = ({ contentCode }: ErrorPageProps) => {
  const content = b2x.useContent<ErrorPageContentType>(contentCode);
  return (
    <MessageBoxPage alignItems="align-items-center" img={content?.body.img} justifyContent="justify-content-center">
      <div className=" px-2 py-5">
        <h2 className="h3 fw-bold py-1">{b2x.formatHtml(content?.body.title)}</h2>
        <p className="py-1">{b2x.formatHtml(content?.body.content)}</p>
        {content?.body.cta && (
          <b2x.DeprecatedCta className="text-uppercase btn btn-primary" cta={content.body.cta}>
            {content.body.cta.label}
          </b2x.DeprecatedCta>
        )}
      </div>
    </MessageBoxPage>
  );
};
