import { b2x } from '@b2x/react/src';

import { RegistrationFormContentType } from '../contentTypes';
import { Page } from './Page';

export interface RegistrationPageProps {}

export const RegistrationPage = (props: RegistrationPageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');

  return (
    <Page greyBackground thingsToLoadBeforePageReady={[content]}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 col-xl-5">
            <div className="mb-4 ">
              <h1 className="fw-bold text-center mb-3">{b2x.formatHtml(content?.body.title)}</h1>
              <p className="text-center">{b2x.formatHtml(content?.body.subTitle)}</p>
            </div>
            <b2x.CustomerForm className="mb-4" />
          </div>
        </div>
      </div>
    </Page>
  );
};
