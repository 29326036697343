export const en = {
  assembledProduct: {
    discountUpTo: '-{{percentage}}%', // da revisionare
    discountUpToMini: '-{{percentage}}%', // da revisionare
  },
  misc: {
    addToCart: 'Add to cart',
    code: 'Code',
    customise: 'Customise',
    storeName: 'De Cecco',
    youSearchedFor: 'Your search:',
  },
  product: {
    freeShippingFrom: 'Free shipping from {{freeShippingFromAmount}}',
  },
  welcome: 'Welcome to De Cecco',
};
