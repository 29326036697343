import { b2x } from '@b2x/react/src';

import { LandingTemplateAPageContentType } from '../contentTypes';
import { SectionLanding } from '../SectionLanding';
import { HeaderBanner } from './HeaderBanner';
import { Page } from './Page';

export interface LandingTemplateAPageProps {}

export const LandingTemplateAPage = (props: LandingTemplateAPageProps) => {
  const page = b2x.usePage<LandingTemplateAPageContentType>();

  return (
    <Page noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="pt-0 pt-xl-5">
        <HeaderBanner {...page?.content?.body.header} />
        <SectionLanding elementRowList={page?.content?.body.content?.elementRowList} />
      </div>
    </Page>
  );
};
