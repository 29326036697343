import './CustomSkuRadio.scss';

import classnames from 'classnames';

import { SkuApiDto } from '../../storefront-api-js-client/src';

export interface CustomSkuRadioProps {
  forceSelected?: boolean;
  label?: string;
  sku: SkuApiDto;
}

export const CustomSkuRadio = ({ forceSelected, label, sku }: CustomSkuRadioProps) => {
  return (
    <div
      className={classnames(
        'custom-sku-radio',
        { 'sku-not-available': sku.state !== 'AVAILABLE' },
        { selected: forceSelected }
      )}
    >
      {label}
    </div>
  );
};
