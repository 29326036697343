import { useAppContext } from './AppContext';
import { getContentSectionByShippingCountry } from './util';

export interface ContentSectionByShippingCountryProps<T> {
  children?(contentSection: T): React.ReactNode;
  contentSections?: Array<{
    itemForThisCountry?: T;
    visibility?: string;
  }>;
}

export const ContentSectionByShippingCountry = <T,>({
  children,
  contentSections,
}: ContentSectionByShippingCountryProps<T>) => {
  const { shippingCountry } = useAppContext();
  const contentSection = getContentSectionByShippingCountry(contentSections, shippingCountry);
  return <>{contentSection && children && children(contentSection)}</>;
};
