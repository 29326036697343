import { Locale } from './interfaces';

export const countriesInfo: Partial<
  Record<
    string,
    {
      i18n: {
        restoreOriginalLocale: string;
        translatableInThisLocale: string;
        translateInThisLocale: string;
        translating: string;
      };
      locale: Locale;
    }
  >
> = {
  // Austria
  AT: {
    i18n: {
      restoreOriginalLocale: 'Wiederherstellen',
      translatableInThisLocale: 'Übersetzbar auf Deutsch',
      translateInThisLocale: 'Übersetzen ins Deutsche',
      translating: 'Übersetzen...',
    },
    locale: { code: 'de-AT', countryCode: 'AT', languageCode: 'de' },
  },
  // Belgium
  BE: {
    i18n: {
      restoreOriginalLocale: 'Herstellen',
      translatableInThisLocale: 'Vertaalbaar in het Nederlands',
      translateInThisLocale: 'Vertalen naar het Nederlands',
      translating: 'Vertalen...',
    },
    locale: { code: 'nl-BE', countryCode: 'BE', languageCode: 'nl' },
  },
  // Bulgaria
  BG: {
    i18n: {
      restoreOriginalLocale: 'Възстановяване',
      translatableInThisLocale: 'Преводим на български',
      translateInThisLocale: 'Превеждане на български',
      translating: 'Превеждане...',
    },
    locale: { code: 'bg-BG', countryCode: 'BG', languageCode: 'bg' },
  },
  // Switzerland
  CH: {
    i18n: {
      restoreOriginalLocale: 'Wiederherstellen',
      translatableInThisLocale: 'Übersetzbar auf Deutsch',
      translateInThisLocale: 'Übersetzen ins Deutsche',
      translating: 'Übersetzen...',
    },
    locale: { code: 'de-CH', countryCode: 'CH', languageCode: 'de' },
  },
  // Czech Republic
  CZ: {
    i18n: {
      restoreOriginalLocale: 'Obnovit',
      translatableInThisLocale: 'Přeložitelné do češtiny',
      translateInThisLocale: 'Přeložit do češtiny',
      translating: 'Překládání...',
    },
    locale: { code: 'cs-CZ', countryCode: 'CZ', languageCode: 'cs' },
  },
  // Germany
  DE: {
    i18n: {
      restoreOriginalLocale: 'Wiederherstellen',
      translatableInThisLocale: 'Übersetzbar auf Deutsch',
      translateInThisLocale: 'Übersetzen ins Deutsche',
      translating: 'Übersetzen...',
    },
    locale: { code: 'de-DE', countryCode: 'DE', languageCode: 'de' },
  },
  // Denmark
  DK: {
    i18n: {
      restoreOriginalLocale: 'Gendan',
      translatableInThisLocale: 'Oversættelig til dansk',
      translateInThisLocale: 'Oversæt til dansk',
      translating: 'Oversættelse...',
    },
    locale: { code: 'da-DK', countryCode: 'DK', languageCode: 'da' },
  },
  // Estonia
  EE: {
    i18n: {
      restoreOriginalLocale: 'Taasta',
      translatableInThisLocale: 'Tõlgitav eesti keelde',
      translateInThisLocale: 'Tõlgi eesti keelde',
      translating: 'Tõlkimine...',
    },
    locale: { code: 'et-EE', countryCode: 'EE', languageCode: 'et' },
  },
  // Spain
  ES: {
    i18n: {
      restoreOriginalLocale: 'Restaurar',
      translatableInThisLocale: 'Traducible al español',
      translateInThisLocale: 'Traducir al español',
      translating: 'Traduciendo...',
    },
    locale: { code: 'es-ES', countryCode: 'ES', languageCode: 'es' },
  },
  // Finland
  FI: {
    i18n: {
      restoreOriginalLocale: 'Palauta',
      translatableInThisLocale: 'Käännettävissä suomeksi',
      translateInThisLocale: 'Käännä suomeksi',
      translating: 'Kääntäminen...',
    },
    locale: { code: 'fi-FI', countryCode: 'FI', languageCode: 'fi' },
  },
  // France
  FR: {
    i18n: {
      restoreOriginalLocale: 'Restaurer',
      translatableInThisLocale: 'Traduisible en français',
      translateInThisLocale: 'Traduire en français',
      translating: 'Traduction...',
    },
    locale: { code: 'fr-FR', countryCode: 'FR', languageCode: 'fr' },
  },
  // Greece
  GR: {
    i18n: {
      restoreOriginalLocale: 'Επαναφορά',
      translatableInThisLocale: 'Μεταφράσιμο στα ελληνικά',
      translateInThisLocale: 'Μετάφραση στα ελληνικά',
      translating: 'Μετάφραση...',
    },
    locale: { code: 'el-GR', countryCode: 'GR', languageCode: 'el' },
  },
  // Croatia
  HR: {
    i18n: {
      restoreOriginalLocale: 'Vrati',
      translatableInThisLocale: 'Prevodivo na hrvatski',
      translateInThisLocale: 'Prevedi na hrvatski',
      translating: 'Prevođenje...',
    },
    locale: { code: 'hr-HR', countryCode: 'HR', languageCode: 'hr' },
  },
  // Hungary
  HU: {
    i18n: {
      restoreOriginalLocale: 'Visszaállítás',
      translatableInThisLocale: 'Fordítható magyarra',
      translateInThisLocale: 'Fordítás magyarra',
      translating: 'Fordítás...',
    },
    locale: { code: 'hu-HU', countryCode: 'HU', languageCode: 'hu' },
  },
  // Ireland
  IE: {
    i18n: {
      restoreOriginalLocale: 'Restore',
      translatableInThisLocale: 'Translatable into English',
      translateInThisLocale: 'Translate to English',
      translating: 'Translating...',
    },
    locale: { code: 'en-IE', countryCode: 'IE', languageCode: 'en' },
  },
  // Italy
  IT: {
    i18n: {
      restoreOriginalLocale: 'Ripristina',
      translatableInThisLocale: 'Traducibile in italiano',
      translateInThisLocale: 'Traduci in italiano',
      translating: 'Traducendo...',
    },
    locale: { code: 'it-IT', countryCode: 'IT', languageCode: 'it' },
  },
  // Lithuania
  LT: {
    i18n: {
      restoreOriginalLocale: 'Atkurti',
      translatableInThisLocale: 'Verčiama į lietuvių kalbą',
      translateInThisLocale: 'Versti į lietuvių kalbą',
      translating: 'Vertimas...',
    },
    locale: { code: 'lt-LT', countryCode: 'LT', languageCode: 'lt' },
  },
  // Luxembourg
  LU: {
    i18n: {
      restoreOriginalLocale: 'Restauréieren',
      translatableInThisLocale: 'Iwwersetzbar op Lëtzebuergesch',
      translateInThisLocale: 'Iwwersetzen op Lëtzebuergesch',
      translating: 'Iwwersetzung...',
    },
    locale: { code: 'lb-LU', countryCode: 'LU', languageCode: 'lb' },
  },
  // Latvia
  LV: {
    i18n: {
      restoreOriginalLocale: 'Atjaunot',
      translatableInThisLocale: 'Tulkojams latviešu valodā',
      translateInThisLocale: 'Tulkot latviešu valodā',
      translating: 'Tulkot...',
    },
    locale: { code: 'lv-LV', countryCode: 'LV', languageCode: 'lv' },
  },
  // Malta
  MT: {
    i18n: {
      restoreOriginalLocale: 'Irrestawra',
      translatableInThisLocale: 'Traduzzibbli bil-Malti',
      translateInThisLocale: 'Ittraduċi bil-Malti',
      translating: 'Traduzzjoni...',
    },
    locale: { code: 'mt-MT', countryCode: 'MT', languageCode: 'mt' },
  },
  // Netherlands
  NL: {
    i18n: {
      restoreOriginalLocale: 'Herstellen',
      translatableInThisLocale: 'Vertaalbaar in het Nederlands',
      translateInThisLocale: 'Vertalen naar het Nederlands',
      translating: 'Vertalen...',
    },
    locale: { code: 'nl-NL', countryCode: 'NL', languageCode: 'nl' },
  },
  // Poland
  PL: {
    i18n: {
      restoreOriginalLocale: 'Przywróć',
      translatableInThisLocale: 'Tłumaczalne na język polski',
      translateInThisLocale: 'Przetłumacz na język polski',
      translating: 'Tłumaczenie...',
    },
    locale: { code: 'pl-PL', countryCode: 'PL', languageCode: 'pl' },
  },
  // Portugal
  PT: {
    i18n: {
      restoreOriginalLocale: 'Restaurar',
      translatableInThisLocale: 'Traduzível para português',
      translateInThisLocale: 'Traduzir para português',
      translating: 'Traduzindo...',
    },
    locale: { code: 'pt-PT', countryCode: 'PT', languageCode: 'pt' },
  },
  // Romania
  RO: {
    i18n: {
      restoreOriginalLocale: 'Restabili',
      translatableInThisLocale: 'Traductibil în română',
      translateInThisLocale: 'Traduceți în română',
      translating: 'Traducere...',
    },
    locale: { code: 'ro-RO', countryCode: 'RO', languageCode: 'ro' },
  },
  // Sweden
  SE: {
    i18n: {
      restoreOriginalLocale: 'Återställ',
      translatableInThisLocale: 'Översättningsbar till svenska',
      translateInThisLocale: 'Översätt till svenska',
      translating: 'Översättning...',
    },
    locale: { code: 'sv-SE', countryCode: 'SE', languageCode: 'sv' },
  },
  // Slovenia
  SI: {
    i18n: {
      restoreOriginalLocale: 'Obnovi',
      translatableInThisLocale: 'Prevedljivo v slovenščino',
      translateInThisLocale: 'Prevedi v slovenščino',
      translating: 'Prevajanje...',
    },
    locale: { code: 'sl-SI', countryCode: 'SI', languageCode: 'sl' },
  },
  // Slovakia
  SK: {
    i18n: {
      restoreOriginalLocale: 'Obnoviť',
      translatableInThisLocale: 'Preložiteľné do slovenčiny',
      translateInThisLocale: 'Preložiť do slovenčiny',
      translating: 'Prekladanie...',
    },
    locale: { code: 'sk-SK', countryCode: 'SK', languageCode: 'sk' },
  },
  // San Marino
  SM: {
    i18n: {
      restoreOriginalLocale: 'Ripristina',
      translatableInThisLocale: 'Traducibile in italiano',
      translateInThisLocale: 'Traduci in italiano',
      translating: 'Traducendo...',
    },
    locale: { code: 'it-SM', countryCode: 'SM', languageCode: 'it' },
  },
};
