import React from 'react';

import { Accordion, AccordionItem } from '../Accordion';
import { Badge, BadgeProps } from '../Badge';
import { Container } from '../Container';
import { Div, H4 } from '../HTMLElement';
import { Icon } from '../Icon';
import { BackgroundOpacity } from '../interfaces';

export interface BadgesProps<ThemeColor, IconName> {
  backgroundColors?: Array<string>;
  backgroundOpacities?: Array<BackgroundOpacity | undefined>;
  backgroundVariants?: Array<ThemeColor>;
  badges?: Array<BadgeProps<ThemeColor>>;
  icons?: Array<IconName | undefined>;
  textColors?: Array<string | undefined>;
  textVariants?: Array<ThemeColor | undefined>;
}

export const Badges = <ThemeColor extends string, IconName extends string>({
  backgroundColors,
  backgroundOpacities = [undefined],
  backgroundVariants,
  badges,
  icons = [undefined],
  textColors,
  textVariants = [undefined],
}: BadgesProps<ThemeColor, IconName>) => {
  return (
    <Container>
      <Div display="flex" flexDirection="column" gap={2}>
        <Div display="flex" gap={2}>
          {badges?.map((badge, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Badge key={index} {...badge} />
          ))}
        </Div>
        <Accordion id="all-badges-accordion">
          <AccordionItem id="all-badges-accordion-item" title="Mostra tutte le badges">
            <Div display="flex" flexDirection="column" gap={2}>
              {[false, true].map((pill) => (
                <React.Fragment key={`${pill}`}>
                  {backgroundOpacities.map((backgroundOpacity) => (
                    <React.Fragment key={backgroundOpacity}>
                      <H4 marginBottom={0}>
                        Pill: {JSON.stringify(pill)}
                        <br />
                        {backgroundOpacity && <>background opacity: {backgroundOpacity}</>}
                      </H4>
                      {icons.map((icon) => (
                        <React.Fragment key={icon}>
                          {textVariants.map((textVariant) => (
                            <Div display="flex" gap={2} key={textVariant}>
                              {backgroundVariants?.map((backgroundVariant) => (
                                <Badge
                                  backgroundOpacity={backgroundOpacity}
                                  backgroundVariant={backgroundVariant}
                                  key={backgroundVariant}
                                  pill={pill}
                                  textVariant={textVariant}
                                >
                                  {icon && <Icon name={icon} />} {backgroundVariant}
                                </Badge>
                              ))}
                              {backgroundColors?.map((backgroundColor) => (
                                <Badge
                                  backgroundColor={backgroundColor}
                                  backgroundOpacity={backgroundOpacity}
                                  key={backgroundColor}
                                  pill={pill}
                                  textVariant={textVariant}
                                >
                                  {icon && <Icon name={icon} />} {backgroundColor}
                                </Badge>
                              ))}
                            </Div>
                          ))}
                          {textColors?.map((textColor) => (
                            <Div display="flex" gap={2} key={textColor}>
                              {backgroundVariants?.map((backgroundVariant) => (
                                <Badge
                                  backgroundOpacity={backgroundOpacity}
                                  backgroundVariant={backgroundVariant}
                                  key={backgroundVariant}
                                  pill={pill}
                                  textColor={textColor}
                                >
                                  {icon && <Icon name={icon} />} {backgroundVariant}
                                </Badge>
                              ))}
                              {backgroundColors?.map((backgroundColor) => (
                                <Badge
                                  backgroundColor={backgroundColor}
                                  backgroundOpacity={backgroundOpacity}
                                  key={backgroundColor}
                                  pill={pill}
                                  textColor={textColor}
                                >
                                  {icon && <Icon name={icon} />} {backgroundColor}
                                </Badge>
                              ))}
                            </Div>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </Div>
          </AccordionItem>
        </Accordion>
      </Div>
    </Container>
  );
};
