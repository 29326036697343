import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
// import { Button } from '../Button';
import { ThankYouPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { MessageBoxPage } from './MessageBoxPage';

export interface ThankYouPageProps {}

export const ThankYouPage = (props: ThankYouPageProps) => {
  const content = b2x.useContent<ThankYouPageContentType>('THANKYOU_PAGE_CONTENT');

  const { bankAccountDetails, order, session } = b2x.useThankYouPage({});

  return (
    <MessageBoxPage alignItems="align-items-center" img={content?.body.img} justifyContent="justify-content-center">
      <div className="px-3 py-5">
        <h2 className="h3 fw-semi-bold py-1"> {b2x.formatHtml(content?.body.title)}</h2>
        <p className="py-1"> {b2x.formatHtml(content?.body.subTitle)}</p>
        {order?.paymentMethod?.type === 'BANK_TRANSFER' ? (
          <>
            <div className="small fw-light">{b2x.formatHtml(content?.body.bankTransfer?.pre)}</div>
            <dl className="row small fw-light">
              <dt className="col-6 fw-medium text-uppercase text-end">{t('misc.amount')}</dt>
              <dd className="col-6 text-start">{b2x.formatCurrency(order.totalCost)}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.ownerName')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.ownerName}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.bankName')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.bankName}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.ibanCode')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.ibanCode}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.bicSwiftCode')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.bicSwiftCode}</dd>
            </dl>
            <div className="small fw-light">{b2x.formatHtml(content?.body.bankTransfer?.post)}</div>
          </>
        ) : (
          <div></div>
          /*           <div className="small fw-light px-3">
            {order?.paymentMethod?.type === 'CASH_ON_DELIVERY' && (
              <>
                <div className="my-3">{b2x.formatHtml(content?.body.cashOnDelivery)}</div>
                <div className="my-3">
                  {t('checkout.thankYou.cashOnDelivery.amountToPayOnDelivery')}:{' '}
                  <strong>{b2x.formatCurrency(order.totalCost)}</strong>
                </div>
              </>
            )}
            {b2x.formatHtml(order?.virtual ? content?.body.contentForVirtualOrder : content?.body.content)}
          </div> */
        )}

        <h6 className="mb-5 mt-5">
          {t('misc.orderCode')}: {order?.code}
        </h6>
        {session?.userLogged && (
          <b2x.router.Link className="btn btn-primary" to="/">
            {b2x.formatHtml(content?.body.cta?.label)}
          </b2x.router.Link>
        )}
      </div>
      {!session?.userLogged && (
        <div className="bg-lighter px-2 py-3">
          <h6 className="mb-4">{b2x.formatHtml(content?.body.registerSection?.content)}</h6>
          <div className="d-flex justify-content-around">
            <Button
              className="pe-3"
              label={b2x.formatHtml(content?.body.registerSection?.ctaContinue?.label)}
              // onClick={handleButtonClick}
              variant="primary"
            />

            <b2x.router.Link className="text-reset fw-semi-bold text-uppercase" to="/">
              {content?.body.registerSection?.ctaCancel?.label}
            </b2x.router.Link>
          </div>
        </div>
      )}
    </MessageBoxPage>
  );
};
