import './ListA.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Button } from './Button';

export interface ListAProps {
  items: Array<{
    cta: string | (() => void);
    disabled?: boolean;
    label: string;
  }>;
  textUnderlined?: boolean;
  textUppercase?: boolean;
}

export const ListA = ({ items, textUnderlined, textUppercase }: ListAProps) => {
  return (
    <div className="ListA hstack gap-2 mb-0">
      {items.length > 0 &&
        items
          .map((item) => (
            <small key={item.label}>
              {typeof item.cta === 'string' ? (
                <b2x.router.Link
                  className={classNames(
                    textUnderlined && 'text-decoration-underline',
                    textUppercase && 'text-uppercase',
                    !textUnderlined && 'text-decoration-none',
                    'text-reset extra-small'
                  )}
                  to={item.cta}
                >
                  {item.label}
                </b2x.router.Link>
              ) : (
                <Button
                  className={classNames(
                    textUnderlined && 'text-decoration-underline',
                    textUppercase && 'text-uppercase',
                    !textUnderlined && 'text-decoration-none',
                    'text-reset extra-small'
                  )}
                  disabled={item.disabled}
                  label={item.label}
                  onClick={item.cta}
                  variant="blank"
                />
              )}
            </small>
          ))
          .reduce(b2x.elementsJoinerReducer(<div className="vr align-self-center"></div>))}
    </div>
  );
};
