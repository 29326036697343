import './BoxC.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { BoxCContentSection } from './contentTypes';

export interface BoxCProps extends BoxCContentSection {
  className?: string;
  style?: React.CSSProperties;
}

export const BoxC = ({ className, slides, style, ...otherProps }: BoxCProps) => {
  return (
    <b2x.Div className={classNames('box-c rounded-3', className)} style={{ ...style, overflow: 'hidden' }}>
      <b2x.SwiperContext>
        <b2x.SwiperFromContent
          {...otherProps}
          className="h-100"
          pagination={{ clickable: true }}
          slides={slides?.map(({ background, content, contentSectionId, cta }) => (
            <b2x.Div
              className="h-100"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              key={contentSectionId}
              padding={{ md: 5, xs: 4 }}
              style={{
                backgroundColor: background?.color,
                backgroundImage: `url(${background?.image?.url})`,
                backgroundPositionX: 'right',
                backgroundPositionY: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '33%',
              }}
            >
              <b2x.Div paddingX={{ lg: 5 }} paddingY={{ lg: 5, xs: 4 }}>
                <div className="h1 text-white mb-3 lh-1">{content}</div>
                <div className="text-center text-lg-start">
                  <b2x.CtaFromContent {...cta} />
                </div>
              </b2x.Div>
            </b2x.Div>
          ))}
        />
      </b2x.SwiperContext>
    </b2x.Div>
  );
};
