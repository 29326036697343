import './NutritionalTable.scss';

import { Container } from './Container';

export interface NutritionalTableProps {}

export const NutritionalTable = (props: NutritionalTableProps) => {
  return (
    <Container>
      <div className="nutritional-table-container">
        <table>
          <tbody>
            <tr>
              <th>Valore energetico</th>
              <td>1490 KJ / 351 kcal</td>
            </tr>
            <tr>
              <th>
                Grassi
                <br />
                <small>Di cui acidi grassi saturi</small>
              </th>
              <td>
                1,5 g<br /> 0,3 g
              </td>
            </tr>
            <tr>
              <th>
                Carboidrati
                <br />
                <small>Di cui zuccheri</small>
              </th>
              <td>
                69 g <br />
                3,4 g
              </td>
            </tr>
            <tr>
              <th>Fibre</th>
              <td>2,9 g</td>
            </tr>
            <tr>
              <th>Proteine</th>
              <td>14 g</td>
            </tr>
            <tr>
              <th>Sale</th>
              <td>0 g</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
};
