import './Header.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import { t } from './i18n/i18n';
import logoSmall from './images/logo-small.svg';
import { MobileHeader } from './MobileHeader';
import { VerticalSlider } from './slider/VerticalSlider';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderFirstRowRef = React.useRef<HTMLDivElement>(null);
  const { headerCheckout } = useAppContext();

  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (desktopHeaderFirstRowRef.current) {
        setHeaderHeight(desktopHeaderFirstRowRef.current.clientHeight);
      }
    }
  }, [desktopHeaderFirstRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      {!headerCheckout ? (
        <>
          <TopBar content={content} toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          <MobileHeader
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="xl"
          />
          <DesktopHeader
            activeCategory={activeCategory}
            firstRowRef={desktopHeaderFirstRowRef}
            localeBoxVisible={localeBoxVisible}
            recalculateHeaderHeight={recalculateHeaderHeight}
            searchBoxVisible={searchBoxVisible}
            setActiveCategory={setActiveCategory}
            setLocaleBoxVisible={setLocaleBoxVisible}
            setSearchBoxVisible={setSearchBoxVisible}
            toggleLocaleBoxVisible={toggleLocaleBoxVisible}
            visibleFrom="xl"
          />
        </>
      ) : (
        <b2x.HeaderCheckout
          logo={logoSmall}
          logoSmall={logoSmall}
          mobileFrom={'md'}
          recalculateHeaderHeight={recalculateHeaderHeight}
        />
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  toggleLocaleBoxVisible(): void;
}

const TopBar = ({ content, toggleLocaleBoxVisible }: TopBarProps) => {
  const { setTopBarDesktopHeight, setTopBarRowRef } = useAppStaticContext();
  const { topBarRowRef } = useAppContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRowRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRowRef]);

  return (
    <div className={'top-bar bg-primary text-secondary'} ref={setTopBarRowRef}>
      <Container>
        <b2x.Row gap={3}>
          <b2x.Col className="d-none d-xl-block" size={{ xl: 3, xs: 12 }}></b2x.Col>
          <b2x.Col size={{ xl: 6, xs: 12 }}>
            {content?.body.topBar?.messagesSlider?.slides ? (
              <VerticalSlider {...content.body.topBar.messagesSlider} />
            ) : (
              <span className="extra-small">{t('welcome')}</span>
            )}
          </b2x.Col>
          <b2x.Col className="d-none d-xl-block" size={{ xl: 3, xs: 12 }}>
            <div className="h-100 d-flex justify-content-end">
              <LocaleToggle toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
            </div>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};

interface LocaleToggleProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleToggle = ({ toggleLocaleBoxVisible }: LocaleToggleProps) => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <Button
      alignItems="center"
      className="text-reset"
      label={
        locale &&
        shippingCountry && <span>{t('header.sendTo', { country: shippingCountry.code, locale: locale.code })}</span>
      }
      onClick={toggleLocaleBoxVisible}
      size="small"
      style={{ fontWeight: '600', textTransform: 'initial' }}
      variant="blank"
    />
  );
};
