import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderMenuItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.png';
import logo15x from './images/logo@1.5x.png';
import logo2x from './images/logo@2x.png';
import logoCompact from './images/logo-compact.png';
import logoCompact15x from './images/logo-compact@1.5x.png';
import logoCompact2x from './images/logo-compact@2x.png';
import logoSmall from './images/logo-small.png';
import logoSmall15x from './images/logo-small@1.5x.png';
import logoSmall2x from './images/logo-small@2x.png';

export interface DesktopHeaderProps {
  activeCategory?: b2x.MenuApiDto;
  firstRowRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  setLocaleBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleLocaleBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({
  activeCategory,
  firstRowRef,
  localeBoxVisible,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  setLocaleBoxVisible,
  setSearchBoxVisible,
  toggleLocaleBoxVisible,
  visibleFrom,
}: DesktopHeaderProps) => {
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();

  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER_DESKTOP: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, [setActiveCategory, setLocaleBoxVisible, setSearchBoxVisible]);

  return (
    <b2x.Sticky offset={-1}>
      {({ isSticky }) => (
        <div
          className={classnames('desktop-header sticky-top bg-white shadow-sm', `d-none d-${visibleFrom}-block`, {
            'show-white-background': localeBoxVisible || searchBoxVisible || activeCategory,
          })}
          ref={firstRowRef}
        >
          <div className={classnames('desktop-header-main')}>
            <Container>
              <b2x.Row gap={0}>
                <b2x.Col size={'auto'}>
                  <b2x.Div alignItems={'start'} className="h-100" display={'flex'}>
                    <div className="logo-container position-relative" style={{ zIndex: 100 }}>
                      {isSticky ? (
                        <div className="logo-compact">
                          <Logo recalculateHeaderHeight={recalculateHeaderHeight} type="compact" />
                        </div>
                      ) : (
                        <div className="logo-regular" style={{ marginBottom: -30 }}>
                          <Logo recalculateHeaderHeight={recalculateHeaderHeight} type="regular" />
                        </div>
                      )}
                    </div>
                  </b2x.Div>
                </b2x.Col>
                <b2x.Col size={''}>
                  <b2x.Row alignItems={'stretch'} className="h-100">
                    <b2x.Col size={12}>
                      <b2x.Div
                        alignItems={'center'}
                        className="h-100"
                        display={'flex'}
                        justifyContent={'end'}
                        paddingTop={2}
                      >
                        <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
                      </b2x.Div>
                    </b2x.Col>
                    <b2x.Col size={12}>
                      <b2x.Div alignItems={'end'} className="h-100 px-5" display={'flex'} justifyContent={'start'}>
                        <Menu
                          activeCategory={activeCategory}
                          menu={menu}
                          setActiveCategory={setActiveCategory}
                          setLocaleBoxVisible={setLocaleBoxVisible}
                          setSearchBoxVisible={setSearchBoxVisible}
                          subMenuDropdownRef={subMenuDropdownRef}
                        />
                      </b2x.Div>
                    </b2x.Col>
                  </b2x.Row>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>

          {activeCategory && (
            <SubMenuDropdown
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              subMenuDropdownRef={subMenuDropdownRef}
            />
          )}
          {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
          {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
        </div>
      )}
    </b2x.Sticky>
  );
};

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <div className="toggles">
      <div className="hstack gap-3">
        <Button
          className="p-2"
          iconEnd={{ name: 'search', size: 25 }}
          onClick={toggleSearchBoxVisible}
          variant="blank"
        />
        {session?.customer ? (
          <b2x.router.Link className="lh-1 text-primary p-2" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="profile" size={25} />
          </b2x.router.Link>
        ) : (
          <Button
            className="text-secondary"
            iconEnd={{ name: 'profile', size: 25 }}
            onClick={showAccountOffcanvas}
            variant="blank"
          />
        )}
        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.router.Link className="btn-wishlist lh-1 p-2" to="/account/area/wishlist">
              <Button className="position-relative text-secondary" variant="blank">
                <Icon name={'wishlist'} size={25} />
                {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                  (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                  <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-light-blue">
                    {(session.wishlist.products ? session.wishlist.products.length : 0) +
                      (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                  </span>
                )}
              </Button>
            </b2x.router.Link>
          ) : (
            <Button
              className="btn-wishlist text-secondary p-2"
              iconEnd={{ name: 'wishlist', size: 25 }}
              onClick={showAccountOffcanvas}
              variant="blank"
            />
          ))}
        <Button className="btn-cart position-relative text-secondary" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-light-blue">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

interface LogoProps {
  recalculateHeaderHeight(): void;
  type: 'regular' | 'small' | 'compact';
}

const Logo = ({ recalculateHeaderHeight, type }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.ImageFromSrc
        onLoad={recalculateHeaderHeight}
        src={
          type === 'regular'
            ? [
                { density: 1, src: logo },
                { density: 1.5, src: logo15x },
                { density: 2, src: logo2x },
              ]
            : type === 'small'
            ? [
                { density: 1, src: logoSmall },
                { density: 1.5, src: logoSmall15x },
                { density: 2, src: logoSmall2x },
              ]
            : [
                { density: 1, src: logoCompact },
                { density: 1.5, src: logoCompact15x },
                { density: 2, src: logoCompact2x },
              ]
        }
        // width={type === 'small' ? 80 : 150}
      />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item d-flex align-items-center', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <b2x.router.Link
        className={classnames(
          firstLevelCategory.cssClass,
          'menu-item-first-level small fw-medium text-uppercase text-decoration-none text-secondary lh-1 text-nowrap px-0 pt-2 pb-3 border-3 border-bottom'
        )}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}

        // to={{ pathname: firstLevelCategory.link }}
      >
        {firstLevelCategory.label}
      </b2x.router.Link>
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  setLocaleBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({
  activeCategory,
  menu,
  setActiveCategory,
  setLocaleBoxVisible,
  setSearchBoxVisible,
  subMenuDropdownRef,
}: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
      setLocaleBoxVisible(false);
      setSearchBoxVisible(false);
    }
  }, [idActiveCategory, setActiveCategory, setLocaleBoxVisible, setSearchBoxVisible]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 250);

  return (
    <div className="menu gap-4 d-flex justify-content-between">
      {menu?.children.map(
        (firstLevelCategory) =>
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  firstLevelCategory.cssClass,
                  'menu-item-first-level small fw-medium text-uppercase text-decoration-none text-secondary lh-1 text-nowrap px-0 pt-2 pb-3 border-3 border-bottom',
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  }
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.label}
              </b2x.router.Link>
            </MenuItemContainer>
          ))
      )}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  const { shippingCountry } = b2x.useAppContext();

  // Se il paese è italia ritorno tutti i children, altrimenti se non è italia torno tutti i children che non hanno il tag IT
  const activeCategoryChildrenFiltered = activeCategory.children.filter(
    (children) => shippingCountry?.code === 'IT' || (shippingCountry?.code !== 'IT' && !children.tags?.includes('IT'))
  );

  return (
    <div className={classnames({ mounted: isMounted }, 'sub-menu-dropdown w-100 pt-4')} ref={subMenuDropdownRef}>
      <Container>
        <div className="h-100 pb-4 pt-3 px-2">
          <b2x.Row className="justify-content-center" cols={activeCategoryChildrenFiltered.length < 6 ? 6 : undefined}>
            {activeCategoryChildrenFiltered.map((secondLevelCategory, index) => {
              return (
                <b2x.Col key={secondLevelCategory.id}>
                  {secondLevelCategory.children.length > 0 && (
                    <RecursiveMenuItem close={close} item={secondLevelCategory} />
                  )}
                </b2x.Col>
              );
            })}
            {activeCategory.description && activeCategory.link && (
              <b2x.Col size={12}>
                <div className="pt-5 text-center">
                  <b2x.router.Link className="btn btn-sm btn-primary px-5" onClick={close} to={activeCategory.link}>
                    {activeCategory.description}
                  </b2x.router.Link>
                </div>
              </b2x.Col>
            )}
          </b2x.Row>
        </div>
      </Container>
    </div>
  );
};

interface RecursiveMenuItemProps {
  close?(): void;
  deep?: number;
  item?: b2x.MenuApiDto;
}

const RecursiveMenuItem = ({ close, deep = 1, item }: RecursiveMenuItemProps) => {
  const { shippingCountry } = b2x.useAppContext();

  // Se il paese è italia ritorno tutti i children, altrimenti se non è italia torno tutti i children che non hanno il tag IT
  const itemChildrenFiltered = item?.children.filter(
    (children) => shippingCountry?.code === 'IT' || (shippingCountry?.code !== 'IT' && !children.tags?.includes('IT'))
  );

  return deep === 1 ? (
    <>
      {itemChildrenFiltered?.map((children) => (
        // Il livello 1 è dedicato alle colonne
        <RecursiveMenuItem deep={deep + 1} item={children} key={children.id} />
      ))}
    </>
  ) : (
    <div className={classnames(`deep-${deep}`, item?.code)}>
      {item?.content && item.content.type === 'HEADER_MENU_ITEM_CONTENT_TYPE'
        ? b2x.typedContent<HeaderMenuItemContentType>(item.content, (content) => (
            <div className="position-relative overflow-hidden rounded-3">
              {content.body.img && <b2x.ImageFromContentV1 {...content.body.img} fluid />}
              {content.body.cta && (
                <div className="position-absolute bottom-0 start-0 px-3 pb-3 d-grid w-100">
                  <b2x.CtaFromContent
                    {...content.body.cta}
                    ctaProps={{ button: { className: 'btn-sm' } }}
                    variant="primary"
                  />
                </div>
              )}
            </div>
          ))
        : item && (
            <div className={classnames({ 'text-center': item.tags?.includes('text-center') })}>
              <b2x.ConditionalWrapper
                condition={item.link !== undefined}
                wrapper={
                  <b2x.router.Link className="text-decoration-none text-secondary" onClick={close} to={item.link} />
                }
              >
                {item.image && (
                  <div className="mb-3">
                    <b2x.Image {...item.image} exclude2x={false} fluid />
                  </div>
                )}
                <h6 className={classnames('primary-font mb-3 fw-medium small')}>
                  <b2x.router.Link className="text-decoration-none text-secondary" onClick={close} to={item.link}>
                    {item.label}
                  </b2x.router.Link>
                </h6>
              </b2x.ConditionalWrapper>
              {itemChildrenFiltered && itemChildrenFiltered.length > 0 && (
                <ul className="list-unstyled small mb-3">
                  {itemChildrenFiltered.map((child) => (
                    <li className="mb-2" key={child.id}>
                      <b2x.router.Link className="text-decoration-none text-secondary" onClick={close} to={child.link}>
                        {child.label}
                      </b2x.router.Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
    </div>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box className="search-box" onCloseButtonClick={toggleSearchBoxVisible}>
      <b2x.Row className="justify-content-center">
        <b2x.Col size={6}>
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </b2x.Col>
      </b2x.Row>
    </Box>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <div className="row justify-content-center text-center">
        <div className="col-5">
          <b2x.LocaleForm />
          <div className="small pt-3">{t('misc.changeCountryMessage')}</div>
        </div>
      </div>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  className?: string;
  onCloseButtonClick(): void;
}

const Box = ({ children, className, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className={classnames(className, 'box pt-3 pb-4')} ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};
