import './CartPage.scss';

import { b2x } from '@b2x/react/src';

// import { MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export type CartPageProps = b2x.CartPageProps;

export const CartPage = (props: CartPageProps) => {
  // const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  // const summaryTopInfoMessage = miscellaneousContent?.body.cartPage?.summaryTopInfo;

  return (
    <Page greyBackground>
      <div className="cart-page">
        <h1 className="text-center mb-4 fw-bold">{t('cart.title')}</h1>
        <b2x.CartPage
          {...props}
          enableContinueShopping
          freeShippingThresholdCriterion="weight"
          // summaryTopInfo={summaryTopInfoMessage ? <SummaryTopInfo message={summaryTopInfoMessage} /> : undefined}
        />
      </div>
    </Page>
  );
};

// interface SummaryTopInfoProps {
//   message?: string;
// }

// const SummaryTopInfo = ({ message }: SummaryTopInfoProps) => {
//   return <div className="p-3 mb-3 bg-white">{b2x.formatHtml(message)}</div>;
// };
