import { Resource } from '../i18n';

export const fr: Resource = {
  assembledProduct: {
    discountUpTo: '-{{percentage}}%', // da revisionare
    discountUpToMini: '-{{percentage}}%', // da revisionare
  },
  form: {
    addToCartForm: {
      buttons: {
        submit: { label: 'Ajouter au panier' },
      },
      misc: {
        productVariants: 'Format',
      },
    },
  },
  header: {
    sendTo: 'Pays : {{country}} | {{locale}}',
  },
  misc: {
    addToCart: 'Ajouter',
    code: 'Code',
    customise: 'Personnaliser',
    storeName: 'De Cecco',
    youSearchedFor: 'Vous avez cherché:',
  },
  product: {
    freeShippingFrom: 'Expéditions gratuites à partir de {{freeShippingFromAmount}}',
    relatedProduct: {
      title: 'Vous pourriez également être intéressé par',
    },
    size: 'Format',
  },
  welcome: 'Bienvenue sur la boutique De Cecco !',
};
