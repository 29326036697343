import { b2x } from '@b2x/react/src';

import { SocialsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface SocialIconsProps {}

export const SocialIcons = (props: SocialIconsProps) => {
  const socialsContent = b2x.useContent<SocialsContentType>('SOCIALS_CONTENT');
  return (
    <>
      <ul className="list-group list-group-horizontal list-unstyled justify-content-center">
        {socialsContent?.body.iconsList &&
          socialsContent.body.iconsList.map((item) => {
            return (
              item.name &&
              item.url &&
              isIconName(item.name) && (
                <li className="me-2" key={item.iconTitle}>
                  {/* eslint-disable-next-line react/forbid-elements */}
                  <a className="text-reset" href={item.url} rel="noreferrer" target="_blank">
                    <Icon fontSize={30} name={item.name as IconName} />
                  </a>
                </li>
              )
            );
          })}
      </ul>
    </>
  );
};
