import './BoxD.scss';

import { b2x } from '@b2x/react/src';

import { BoxDContentSection } from './contentTypes';

export interface BoxDProps extends BoxDContentSection {}

export const BoxD = ({ copy, image }: BoxDProps) => {
  return (
    <b2x.Div className="box-d rounded-3 d-flex justify-content-center">
      <b2x.Div className="image-container position-relative">
        <b2x.ImageFromContentV2 {...image} fluid />
        {(copy?.title || copy?.content) && (
          <b2x.Div className="copy-container position-absolute top-0 start-0 w-100 pt-4 px-3 mt-2 text-center">
            {copy.title && (
              <b2x.Div className="title fs-4 fw-bold text-uppercase">{b2x.formatHtml(copy.title)}</b2x.Div>
            )}
            {copy.content && <b2x.Div className="content fw-semi-bold">{b2x.formatHtml(copy.content)}</b2x.Div>}
          </b2x.Div>
        )}
      </b2x.Div>
    </b2x.Div>
  );
};
