import './LandingTemplateBPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { BoxD } from '../BoxD';
import { BoxE } from '../BoxE';
import { Container } from '../Container';
import { LandingTemplateBPageContentType } from '../contentTypes';
import { ProductTile } from '../ProductTile';
import { HeaderBanner } from './HeaderBanner';
import { Page } from './Page';

export interface LandingTemplateBPageProps {}

export const LandingTemplateBPage = (props: LandingTemplateBPageProps) => {
  const page = b2x.usePage<LandingTemplateBPageContentType>();

  return (
    <Page className="landing-template-b" noPaddingBottom thingsToLoadBeforePageReady={[page]}>
      <HeaderBanner {...page?.content?.body.header} />
      <section className="slider-section py-3 py-lg-5 mb-lg-5 mb-3">
        <Container>
          <b2x.Row justifyContent={'center'}>
            <b2x.Col size={{ xl: 10, xs: 12 }}>
              <b2x.Div marginBottom={4} textAlign={'center'}>
                <b2x.H2 className="text-uppercase">{b2x.formatHtml(page?.content?.body.sliderSection?.title)}</b2x.H2>
                <b2x.P>{b2x.formatHtml(page?.content?.body.sliderSection?.content)}</b2x.P>
              </b2x.Div>
              <b2x.SwiperContext>
                <b2x.Swiper
                  breakpoints={{ lg: { slidesPerView: 3 }, xs: { centeredSlides: true, slidesPerView: 1.25 } }}
                  className="slider-section-swiper"
                  pagination
                  slides={page?.content?.body.sliderSection?.slides?.map((slide) => (
                    <b2x.Div key={slide.contentSectionId} marginBottom={3}>
                      <BoxD {...slide.boxD} />
                    </b2x.Div>
                  ))}
                  spaceBetween={32}
                />
              </b2x.SwiperContext>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </section>
      {page?.content?.body.productsSection?.map((productsSecion, index) => (
        <section
          className={classnames(`products-section-${index}`, 'mb-lg-5 mb-3 pb-lg-5 pb-3')}
          key={productsSecion.contentSectionId}
        >
          <Container>
            <b2x.Row>
              <b2x.Col size={{ lg: 6, xs: 12 }}>
                <b2x.Div paddingBottom={{ lg: 5, xs: 3 }} paddingTop={3}>
                  <BoxE {...productsSecion.boxE} />
                </b2x.Div>
              </b2x.Col>
              {/* Sezione da sostituire alla sottostante */}
              {/* {productsSecion.products && (
                <b2x.ProductsByIds ids={productsSecion.products.map(({ id }) => id)}>
                  {(products) => (
                    <b2x.EqualHeight>
                      <b2x.Listing name={`Landing box line - ${index}`} products={products}>
                        {products?.map((product) => (
                          <b2x.Col key={product.id} size={{ lg: 3, xs: 6 }}>
                            <ProductTile product={product} />
                          </b2x.Col>
                        ))}
                      </b2x.Listing>
                    </b2x.EqualHeight>
                  )}
                </b2x.ProductsByIds>
              )} */}
              {productsSecion.products?.map(({ contentSectionId, id }, productIndex) => (
                <b2x.Col key={contentSectionId} size={{ lg: 3, xs: 6 }}>
                  {id && (
                    <b2x.ProductById id={id}>
                      {(product) =>
                        product !== undefined && (
                          <b2x.EqualHeight>
                            <b2x.Listing name={`Landing box - ${productIndex}`} products={[product]}>
                              <ProductTile product={product} />
                            </b2x.Listing>
                          </b2x.EqualHeight>
                        )
                      }
                    </b2x.ProductById>
                  )}
                </b2x.Col>
              ))}
            </b2x.Row>
          </Container>
        </section>
      ))}
    </Page>
  );
};
