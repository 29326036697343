import { ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { AssembledComponentSku } from './useProductAssembler';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface AssembledComponentSkuTileProps {
  assembledProduct: ProductApiDto;
  componentSku: AssembledComponentSku;
}

const AssembledComponentSkuTile = (props: AssembledComponentSkuTileProps) => {
  return <>Must be overwritten</>;
};

export type AssembledComponentSkuTileVariants = '';

const AssembledComponentSkuTileController = (props: PropsWithCustomComponent<AssembledComponentSkuTileProps>) => (
  <VariantsController<AssembledComponentSkuTileProps, AssembledComponentSkuTileVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: AssembledComponentSkuTile,
      name: 'AssembledComponentSkuTile',
    }}
  />
);
export { AssembledComponentSkuTileController as AssembledComponentSkuTile };
