// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { Locale } from '../interfaces';
import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useTranslationsApi = () => {
  const { apiRequest } = useApiRequest();

  const translateTexts = React.useCallback(
    (texts: Array<string>, targetLocale: Locale, config?: ApiRequestConfig) =>
      apiRequest(api.translations.translateTexts(texts, targetLocale.code), { ...config }),
    [apiRequest]
  );

  return {
    translateTexts,
  };
};
