import { Resource } from '../i18n';

export const it: Resource = {
  assembledProduct: {
    discountUpTo: '-{{percentage}}%', // da revisionare
    discountUpToMini: '-{{percentage}}%', // da revisionare
    summary: {
      title: 'Riepilogo',
    },
  },
  form: {
    addToCartForm: {
      buttons: {
        submit: { label: 'Aggiungi al carrello' },
      },
      misc: {
        productVariants: 'Formato',
      },
    },
  },
  header: {
    sendTo: 'Paese: {{country}} | {{locale}}',
  },
  misc: {
    addToCart: 'Aggiungi',
    code: 'Codice',
    customise: 'Personalizza',
    storeName: 'De Cecco',
    youSearchedFor: 'Hai cercato:',
  },
  product: {
    freeShippingFrom: 'Spedizioni gratuite a partire da {{freeShippingFromAmount}}',
    relatedProduct: {
      title: 'Ti potrebbe piacere anche',
    },
    size: 'Formato',
  },
  welcome: 'Benvenuto su store De Cecco!',
};
