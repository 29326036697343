import { b2x } from '@b2x/react/src';

import { TopSectionAContentSection } from './contentTypes';

export interface TopSectionAProps extends TopSectionAContentSection {}

export const TopSectionA = ({ backgroundVariant, subtitle, textAlign, textVariant, title }: TopSectionAProps) => {
  return (
    <>
      {(title || subtitle) && (
        <b2x.Div
          background={backgroundVariant}
          paddingY={{ lg: 5, xs: 4 }}
          textAlign={textAlign}
          textVariant={textVariant}
        >
          <b2x.Container>
            <b2x.Div alignItems="center" display="flex" flexDirection={'column'} gap={2} justifyContent="center">
              <b2x.Div display={{ lg: 'flex', xs: 'none' }}>
                <b2x.Breadcrumb hideLastItem textVariant="white" />
              </b2x.Div>
              <b2x.Div className="h1" fontWeight={700} textTransform="uppercase">
                {title}
              </b2x.Div>
              <b2x.Div className="h4 primary-font">{b2x.formatHtml(subtitle)}</b2x.Div>
            </b2x.Div>
          </b2x.Container>
        </b2x.Div>
      )}
    </>
  );
};
