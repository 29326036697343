import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Button } from './Button';
import { BoxAContentSection } from './contentTypes';
import { Icon } from './Icon';

export interface BoxAProps extends BoxAContentSection {
  className?: string;
  style?: React.CSSProperties;
}

export const BoxA = ({ background, className, cta, slider, style, title }: BoxAProps) => {
  return (
    <b2x.Div
      className={classNames('rounded-3', className)}
      display="flex"
      flexDirection="column"
      padding={{ lg: 4, xs: 3 }}
      style={{
        ...style,
        backgroundColor: background?.color,
        backgroundImage: `url(${background?.image?.url})`,
      }}
      textAlign="center"
    >
      <div>
        <h2 className="fw-bold text-uppercase mb-2">{title}</h2>
        <b2x.CtaFromContent {...cta} ctaProps={{ button: { className: 'mb-3', variant: 'undefined' } }} />
      </div>
      <div style={{ flex: 1 }}>
        <div className="h-100 d-flex " style={{ flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <b2x.SwiperContext>
              {({ navigationNextElRef, navigationPrevElRef, swiper }) => (
                <b2x.SwiperFromContent
                  {...slider}
                  className="position-relative"
                  navigation={{ custom: true }}
                  slides={slider?.slides?.map(
                    ({ contentSectionId, image }) =>
                      image && <b2x.NotResponsiveImageFromContent key={contentSectionId} {...image} fluid />
                  )}
                >
                  {slider?.slides?.length && slider.slides.length > 1 && (
                    <>
                      <Button
                        className="rounded-circle position-absolute top-50 start-0 translate-middle-y"
                        innerRef={navigationPrevElRef}
                        style={{
                          background: 'white',
                          height: 60,
                          visibility: swiper?.isBeginning ? 'hidden' : undefined,
                          width: 60,
                          zIndex: 2,
                        }}
                        variant="blank"
                      >
                        <Icon name="large-arrow-left" size={25} />
                      </Button>
                      <Button
                        className="rounded-circle position-absolute top-50 end-0 translate-middle-y"
                        innerRef={navigationNextElRef}
                        style={{
                          background: 'white',
                          height: 60,
                          visibility: swiper?.isEnd ? 'hidden' : undefined,
                          width: 60,
                          zIndex: 2,
                        }}
                        variant="blank"
                      >
                        <Icon name="large-arrow-right" size={25} />
                      </Button>
                    </>
                  )}
                </b2x.SwiperFromContent>
              )}
            </b2x.SwiperContext>
          </div>
        </div>
      </div>
    </b2x.Div>
  );
};
