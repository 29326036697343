import './GiftCardPage.scss';

import { b2x } from '@b2x/react/src';

import { Page } from './Page';

export interface GiftCardPageProps {}

export const GiftCardPage = (props: GiftCardPageProps) => {
  const product = b2x.useProduct({ populate: { priceRange: true, skus: { attributes: true, price: true } } });

  return (
    <Page thingsToLoadBeforePageReady={[product]}>
      <div className="gift-card-page">
        {product && (
          <b2x.AddToCartFormHelper product={product} scope="product">
            {({ fieldsHelper, image }) => <>Gift card</>}
          </b2x.AddToCartFormHelper>
        )}
      </div>
    </Page>
  );
};
