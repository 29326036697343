import React from 'react';

import { Button } from '../Button';
import { Col } from '../Col';
import { Container } from '../Container';
import { Div } from '../HTMLElement';
import { ImagePlaceholder } from '../ImagePlaceholder';
import { Row } from '../Row';
import { Swiper, SwiperClass } from '../Swiper';
import { SwiperContext } from '../SwiperContext';

export interface SlidersProps {}

export const Sliders = (props: SlidersProps) => {
  return (
    <Container>
      <Row>
        <Block title="Pagination">
          <SwiperContext>
            <Swiper
              pagination={{ clickable: true }}
              slides={[1, 2, 3, 4, 5].map((el) => (
                <Content index={el} key={el} />
              ))}
              spaceBetween={10}
            />
          </SwiperContext>
        </Block>
        <Block title="Navigation">
          <SwiperContext>
            <Swiper
              navigation
              slides={[1, 2, 3, 4, 5].map((el) => (
                <Content index={el} key={el} />
              ))}
              spaceBetween={10}
            />
          </SwiperContext>
        </Block>
        <Block title="Scrollbar">
          <SwiperContext>
            <Swiper
              scrollbar={{ draggable: true }}
              slides={[1, 2, 3, 4, 5].map((el) => (
                <Content index={el} key={el} />
              ))}
              spaceBetween={10}
            />
          </SwiperContext>
        </Block>
        <Block title="Pagination (custom)">
          <SwiperContext>
            {({ paginationElRef }) => (
              <>
                <Swiper
                  pagination={{ clickable: true, custom: true }}
                  slides={[1, 2, 3, 4, 5].map((el) => (
                    <Content index={el} key={el} />
                  ))}
                  spaceBetween={10}
                />
                <Div display="flex" innerRef={paginationElRef} justifyContent="center" marginTop={2} />
              </>
            )}
          </SwiperContext>
        </Block>
        <Block title="Navigation (custom)">
          <SwiperContext>
            {({ navigationNextElRef, navigationPrevElRef }) => (
              <>
                <Swiper
                  navigation={{ custom: true }}
                  slides={[1, 2, 3, 4, 5].map((el) => (
                    <Content index={el} key={el} />
                  ))}
                  spaceBetween={10}
                />
                <Div display={'flex'} gap={2} justifyContent={'center'} marginTop={2}>
                  <Button innerRef={navigationPrevElRef} label="prev" size="small" />
                  <Button innerRef={navigationNextElRef} label="next" size="small" />
                </Div>
              </>
            )}
          </SwiperContext>
        </Block>
        <Block title="Scrollbar (custom)">
          <SwiperContext>
            {({ scrollbarElRef }) => (
              <>
                <Swiper
                  scrollbar={{ custom: true, draggable: true }}
                  slides={[1, 2, 3, 4, 5].map((el) => (
                    <Content index={el} key={el} />
                  ))}
                  spaceBetween={10}
                />
                <Div
                  border={{ color: '#ddd', style: 'solid', width: 1 }}
                  innerRef={scrollbarElRef}
                  marginTop={2}
                  style={{ height: 20 }}
                />
              </>
            )}
          </SwiperContext>
        </Block>
        <Block title="Controller">
          <ControllerExample />
        </Block>
        <Block title="Controller (two-way)">
          <TwoWayControllerExample />
        </Block>
        <Block title="Thumbs">
          <ThumbsExample />
        </Block>
      </Row>
    </Container>
  );
};

interface BlockProps {
  children?: React.ReactNode;
  title: string;
}
const Block = ({ children, title }: BlockProps) => (
  <Col size={{ md: 4, xs: 12 }}>
    <Div marginBottom={5}>
      <h3 className="text-center">{title}</h3>
      {children}
    </Div>
  </Col>
);

interface ContentProps {
  index: number;
  text?: string;
}

const Content = ({ index, text = 'Slide' }: ContentProps) => (
  <ImagePlaceholder aspectRatio={16 / 5} backgroundColor="#ccc" label={`${text} ${index}`} labelColor="#555" />
);

const ControllerExample = () => {
  const [controlledSwiper, setControlledSwiper] = React.useState<SwiperClass>();

  return (
    <>
      <Div marginBottom={2}>
        <SwiperContext>
          <Swiper
            controller={{ control: controlledSwiper }}
            slides={[1, 2, 3, 4, 5].map((el) => (
              <Content index={el} key={el} />
            ))}
            spaceBetween={10}
          />
        </SwiperContext>
      </Div>
      <SwiperContext>
        <Swiper
          onSwiper={setControlledSwiper}
          slides={[1, 2, 3, 4, 5].map((el) => (
            <Content index={el} key={el} />
          ))}
          spaceBetween={10}
        />
      </SwiperContext>
    </>
  );
};

const TwoWayControllerExample = () => {
  const [firstSwiper, setFirstSwiper] = React.useState<SwiperClass>();
  const [secondSwiper, setsecondSwiper] = React.useState<SwiperClass>();

  return (
    <>
      <Div marginBottom={2}>
        <SwiperContext>
          <Swiper
            controller={{ control: secondSwiper }}
            onSwiper={setFirstSwiper}
            slides={[1, 2, 3, 4, 5].map((el) => (
              <Content index={el} key={el} />
            ))}
            spaceBetween={10}
          />
        </SwiperContext>
      </Div>
      <SwiperContext>
        <Swiper
          controller={{ control: firstSwiper }}
          onSwiper={setsecondSwiper}
          slides={[1, 2, 3, 4, 5].map((el) => (
            <Content index={el} key={el} />
          ))}
          spaceBetween={10}
        />
      </SwiperContext>
    </>
  );
};

const ThumbsExample = () => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperClass>();

  return (
    <>
      <Div marginBottom={2}>
        <SwiperContext>
          <Swiper
            slides={[1, 2, 3, 4, 5].map((el) => (
              <Content index={el} key={el} />
            ))}
            spaceBetween={10}
            thumbs={{
              slideThumbActiveClass: 'border',
              swiper: thumbsSwiper,
            }}
          />
        </SwiperContext>
      </Div>
      <SwiperContext>
        <Swiper
          onSwiper={setThumbsSwiper}
          slides={[1, 2, 3, 4, 5].map((el) => (
            <Content index={el} key={el} text="Thumb" />
          ))}
          slidesPerView={3}
          spaceBetween={10}
          watchSlidesProgress
        />
      </SwiperContext>
    </>
  );
};
